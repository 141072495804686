import React from 'react'

const Breadcumbs = (props) => {
  
  let buttonData = {
      show: '',
      title: '',
      icon: '',
      classbtn: '',
      url: ''
  }

  const constructObject = arr => {
    let array = []
    for (let index = 0; index < arr.length; index++) {
      if (index === 0) {
        if(arr[index][0] === true){
          buttonData.show = arr[index][0];
          buttonData.title = arr[index][1];
          buttonData.icon = arr[index][2];
          buttonData.classbtn = arr[index][3];
          buttonData.url = arr[index][4];
        }
        else{
          buttonData.show = arr[index][0]
        }
        
      } else {
        array.push({item:arr[index][0],url:arr[index][1]})
      }
    }
    return array;
  };

    const items = constructObject(props.data);
    // console.log(items);
    // console.log(buttonData);
    
    return (
        <div className="content-header" style={{padding:'5px'}}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div style={{ display: 'flex' }} className="col-sm-6">
                <h1 className="m-0 text-dark">{items[items.length-1].item}</h1>
                <a hidden={!buttonData.show} style={{ marginInline: '25px'}} className={buttonData.classbtn} href={buttonData.url}><i className={buttonData.icon}></i>{buttonData.title}</a>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                    {items.map((item,i)=>{
                        return(
                            item.url ? 
                                <li key={i} className="breadcrumb-item"><a href={item.url}>{item.item}</a></li> 
                                :
                                <li key={i} className="breadcrumb-item active">{item.item}</li>        
                        )
                    })}
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>
    )
}

export default Breadcumbs

