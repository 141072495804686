const initialdata = [
    {
        id: '',
        feeTitle: '',
        feeAmount: '',
        feeNote: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (miscellaneousfees = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_Miscellaneous_Fees':
            miscellaneousfees = action.payload.data;
            return miscellaneousfees;
        case 'ADD_NEW_Miscellaneous_Fee':
            let newfee = action.payload;
            miscellaneousfees = [ ...miscellaneousfees, newfee];
            return miscellaneousfees;
        case 'UPDATE_Miscellaneous_Fee':
            for (let index = 0; index < miscellaneousfees.length; index++) {
                if( miscellaneousfees[index].id === action.payload.id) {
                    miscellaneousfees[index] = action.payload;
                }
            }
            return miscellaneousfees
        case 'REMOVE_Miscellaneous_Fee':
            let x=0;
            for (let index = 0; index < miscellaneousfees.length; index++) {
                if( miscellaneousfees[index].id === action.payload) {
                    x=index;
                }
            }    
            miscellaneousfees.splice(x, 1);
            // console.log(categories);
            return miscellaneousfees
        default:
            return miscellaneousfees;
    }
}