import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getmiscellaneousdata, updatemiscellaneous, removemiscellaneous, addmiscellaneous } from '../actions/miscellaneousFeeActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const MiscellaneousFee = () => {
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)
    
    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editFormData, setEditFormData] = useState({
        feeTitle: '',
        feeAmount: '',
        feeNote: '',
    })
    
    const handleEditChange = text => e => {
        setEditFormData({...editFormData, [text]: e.target.value})
    }
    // edit Form Fields

    // create Form Fields
    const [createFormData, setCreateFormData] = useState({
        feeTitle: '',
        feeAmount: '',
        feeNote: '',
    })
    
    const handleCreateChange = text => e => {
        setCreateFormData({...createFormData, [text]: e.target.value})
    }
    // create Form Fields
    
    // table state
    const [data, setData] = useState([]);
    const [isLoadingDataError, setIsLoadingDataError] = useState(false);
    const [isLoadingData, setIsLoadingData ] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isDeletingData, setIsDeletingData] = useState(false);
    const [isCreatingData, setIsCreatingData] = useState(false);
    const [isUpdatingData, setIsUpdatingData] = useState(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'feeTitle',
                header: 'Miscellaneous Fee Title',
                enableEditing: true,
            },
            {
                accessorKey: 'feeAmount',
                header: 'Miscellaneous Fee Amount',
                enableEditing: true
            },
            {
                accessorKey: 'feeNote',
                header: 'Miscellaneous Fee Note',
                enableEditing: false,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );
    
     //CREATE action
     const handleCreateData = async ({ values, table }) => {
        setIsCreatingData(true)
        let formsdata = new FormData();
        formsdata.append('feeTitle', createFormData.feeTitle);
        formsdata.append('feeAmount', createFormData.feeAmount);
        formsdata.append('feeNote', createFormData.feeNote);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/miscellaneousfee/createmiscellaneousfee`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addmiscellaneous(res.data.fee));
            fetchData();
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveData = async ({ values, table }) => {
        setIsUpdatingData(true)
        let formsdata = new FormData();
        formsdata.append('id', values.id);
        formsdata.append('feeTitle', editFormData.feeTitle===''?values.feeTitle:editFormData.feeTitle);
        formsdata.append('feeAmount', editFormData.feeAmount===''?values.feeAmount:editFormData.feeAmount);
        formsdata.append('feeNote', editFormData.feeNote===''?values.feeNote:editFormData.feeNote);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/miscellaneousfee/updatemiscellaneousfee`,formsdata,config);
            toast.success(res.data.message);
            dispatch(updatemiscellaneous(res.data.fee));
            fetchData();
            setEditFormData({feeTitle: '', feeAmount: '', feeNote: '',})
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingData(true)
        if (window.confirm('Are you sure you want to delete this Miscellaneous Fees?')) {
            axios.delete(`${process.env.REACT_APP_API_URL}/miscellaneousfee/removemiscellaneousfee/${row.original.id}`,config)
            .then(res => {
                dispatch(removemiscellaneous(row.original.id));
                fetchData();
                setIsDeletingData(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingData(false)
                toast.error(err.response.data.message);
            })
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingDataError
            ?   {
                    color: 'error',
                    children: 'Error loading data',
                }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateData,
        onEditingRowSave: handleSaveData,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Add Miscellaneous Fees</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Fee Title</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('feeTitle')} value={createFormData.feeTitle} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Fee Amount</label>
                                <div className="input-group">
                                    <input type='number' onChange={handleCreateChange('feeAmount')} value={createFormData.feeAmount} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-dollar-sign"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Fee Note</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('feeNote')} value={createFormData.feeNote} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h3">Edit Miscellaneous Fees</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">
                        
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Fee Title</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('feeTitle')} value={editFormData.feeTitle===""?row.original.feeTitle:editFormData.feeTitle} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Fee Amount</label>
                                <div className="input-group">
                                    <input type='number' onChange={handleEditChange('feeAmount')} value={editFormData.feeAmount===""?row.original.feeAmount:editFormData.feeAmount} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-dollar-sign"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Fee Note</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('feeNote')} value={editFormData.feeNote===""?row.original.feeNote:editFormData.feeNote} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
              variant="contained"
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
              }}
            >
              Add Miscellaneous Fees
            </Button>
        ),
        state: {
            isLoading: isLoadingData,
            isSaving: isCreatingData || isUpdatingData || isDeletingData,
            showAlertBanner: isLoadingDataError,
            showProgressBars: isFetchingData,
        },
    });

    const fetchData = useCallback( async () => {
        try {
            dispatch(getmiscellaneousdata());
            await delay(1000);
            setIsFetchingData(false)
            setIsLoadingData(false)
            let array = []
            store.getState().miscellaneousfees.forEach(element => {
                array.push({
                    id: element.id,
                    feeTitle: element.feeTitle,
                    feeAmount: element.feeAmount,
                    feeNote: element.feeNote,
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
        } catch (error) {
            setIsLoadingDataError(true)
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchData()    
    }, [fetchData]);

    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer />
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Miscellaneous Fees',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default MiscellaneousFee
