import * as api from '../api';   


// get all departments
export const getdepartmentsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetDepartmentsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_Departments', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new department
export const adddepartment = (NewDept) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_Department', payload: NewDept});
    } catch (error) {
        console.log(error);
    }
}

// update department
export const updatedepartment = (dept) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_Department', payload: dept});
    } catch (error) {
        console.log(error);
    }
}

// remove department with id
export const removedepartment = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_Department', payload: id});
    } catch (error) {
        console.log(error);
    }
}