import * as api from '../api';   


// get all Announcements
export const getAnnouncementsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetAnnouncementsData()
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_ANNOUNCEMENTS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new Announcements
export const addnewAnnouncement = (NewAnnouncement) => async (dispatch) => {
    try {
        
        dispatch({ type: 'ADD_NEW_ANNOUNCEMENT', payload: NewAnnouncement});
    } catch (error) {
        console.log(error);
    }
}

// update Announcements
export const updateAnnouncements = (Announcement) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_ANNOUNCEMENT', payload: Announcement});
    } catch (error) {
        console.log(error);
    }
}

// remove Announcements with id
export const removeAnnouncement = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_ANNOUNCEMENT', payload: id});
    } catch (error) {
        console.log(error);
    }
}