const initialdata = {
    adAppForm: null,
    adFormalitiesDate: null,
    adResultDate: null,
    adEligibleDate: null,
    adStatus: null,
    adTestDate: null,
    adTestFee: null,
    admissionFeeMerit: null,
    admissionFeeSelf: null,
    admissionFeeOverseas: null,
    createdAt: null,
    id: null,
    lastDateToApply: null,
    securityDeposit: null,
    updatedAt: null
}

// eslint-disable-next-line
export default  (admissions = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ADMISSIONS_DATA':
            admissions = action.payload.data;
            return admissions;
        case 'UPDATE_ADMISSIONS_DATA':
            admissions = action.payload.data;
            return admissions;
        default:
            return admissions;
    }
}