const initialdata = [
    {
        id: '',
        title: '',
        description: '',
        slideImage: '',
        slideStatus: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (sliders = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_SLIDES':
            sliders = action.payload.data;
            return sliders;
        case 'ADD_NEW_SLIDE':
            let newslide = action.payload;
            sliders = [ ...sliders, newslide];
            return sliders;
        case 'UPDATE_SLIDE':
            for (let index = 0; index < sliders.length; index++) {
                if( sliders[index].id === action.payload.id) {
                    sliders[index] = action.payload;
                }
            }
            return sliders
        case 'REMOVE_SLIDE':
            let x=0;
            for (let index = 0; index < sliders.length; index++) {
                if( sliders[index].id === action.payload) {
                    x=index;
                }
            }    
            sliders.splice(x, 1);
            // console.log(categories);
            return sliders
        default:
            return sliders;
    }
}