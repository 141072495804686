import * as api from '../api';   


// get all events
export const geteventsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetEventsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_EVENTS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new EVENT
export const addnewevent = (NewEvent) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_EVENT', payload: NewEvent});
    } catch (error) {
        console.log(error);
    }
}

// update EVENT
export const updateevent = (Event) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_EVENT', payload: Event});
    } catch (error) {
        console.log(error);
    }
}

// remove EVENT with id
export const removeevent = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_EVENT', payload: id});
    } catch (error) {
        console.log(error);
    }
}