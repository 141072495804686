const initialdata = [
    {
        id: '',
        semester: '',
        resultsFile: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (studentresults = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_STUDENTS_RESULT':
            studentresults = action.payload.data;
            return studentresults;
        case 'ADD_NEW_STUDENTS_RESULT':
            let newresult = action.payload;
            studentresults = [ ...studentresults, newresult];
            return studentresults;
        case 'UPDATE_STUDENTS_RESULT':
            for (let index = 0; index < studentresults.length; index++) {
                if( studentresults[index].id === action.payload.id) {
                    studentresults[index] = action.payload;
                }
            }
            return studentresults
        case 'REMOVE_STUDENTS_RESULT':
            let x=0;
            for (let index = 0; index < studentresults.length; index++) {
                if( studentresults[index].id === action.payload) {
                    x=index;
                }
            }    
            studentresults.splice(x, 1);
            return studentresults
        default:
            return studentresults;
    }
}