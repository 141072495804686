import * as api from '../api';   


// get all Exam Schedules
export const getExamSchedulesdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetExamSchedulesData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_EXAMSCHEDULES', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new EXAMSCHEDULE
export const addnewexamschedule = (NewSchedule) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_EXAMSCHEDULES', payload: NewSchedule});
    } catch (error) {
        console.log(error);
    }
}

// update EXAMSCHEDULE
export const updateexamschedule = (Examschedule) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_EXAMSCHEDULES', payload: Examschedule});
    } catch (error) {
        console.log(error);
    }
}

// remove EXAMSCHEDULE with id
export const removeexamschedule = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_EXAMSCHEDULES', payload: id});
    } catch (error) {
        console.log(error);
    }
}