import * as api from '../api';   


// get all facilitiess
export const getfacilitiessdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetFacilitiesData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_FACILITIES', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new FACILITY
export const addnewfacilities = (NewFacility) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_FACILITY', payload: NewFacility});
    } catch (error) {
        console.log(error);
    }
}

// update FACILITY
export const updatefacilities = (Facilities) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_FACILITY', payload: Facilities});
    } catch (error) {
        console.log(error);
    }
}

// remove FACILITY with id
export const removefacilities = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_FACILITY', payload: id});
    } catch (error) {
        console.log(error);
    }
}