import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie, updateUser } from '../helpers/auth';
import { delay } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons  } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getusersdata, removeuser, addnewuser } from '../actions/userActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const Home = () => { 
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)
    
    // Get Authenticated User
    let user = { createdAt: '' ,email: '' ,name: '' ,profilePic: '' ,superUser: '' ,updatedAt: ''};
    if(isAuth()){ user = isAuth(); }

    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editFormData, setEditFormData] = useState({
        name: user.name,
        email: user.email,
        password: '',
        profilePic: process.env.REACT_APP_API_URL+'/'+user.profilePic,
    })

    const [editfile, setEditFile] = useState('');
    const [editfileName, setEditFilename] = useState('')

    const onEditFileChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Please select valid image: jpg | jpeg | png')
        } else {
            if(e.target.files[0].size >= 1000000){
                toast.error('Please select image size less then 1MB')
            } else {
                setEditFile(e.target.files[0]);
                setEditFilename(e.target.files[0].name);
            }
        }
    };
    
    const handleEditChange = text => e => {
        setEditFormData({...editFormData, [text]: e.target.value})
    }
    // edit Form Fields

    // create Form Fields
    const [createUserData, setCreateUserData] = useState({
        createUser_name: '',
        createUser_email: '',
        createUser_password: '',
    })
    const [createUser_file, setcreateUserFile] = useState('');
    const [createUser_fileName, setcreateUserFilename] = useState('')

    const handleUserDataChange = text => e => {
        setCreateUserData({...createUserData, [text]: e.target.value})
    }

    const onCreateUserFileChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Please select valid image: jpg | jpeg | png')
        } else {
            if(e.target.files[0].size >= 1000000){
                toast.error('Please select image size less then 1MB')
            } else {
                setcreateUserFile(e.target.files[0]);
                setcreateUserFilename(e.target.files[0].name);
            }
        }
    };
    // create Form Fields

    // table state
    const [data, setData] = useState([]);
    const [isLoadingUsersError, setIsLoadingUsersError] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers ] = useState(true);
    const [isFetchingUsers, setIsFetchingUsers] = useState(true);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                enableEditing: true,
            },
            {
                accessorKey: 'email',
                header: 'Email',
                enableEditing: true
            },
            {
                accessorKey: 'profilePic',
                header: 'Profile Pic',
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      justifyContent: 'center'
                    }}
                    
                  >
                    <img
                      alt="avatar"
                      height={75}
                      src={renderedCellValue}
                      loading="lazy"
                      style={{  }}
                    />
                  </Box>
                ),
                enableEditing: true,
            },
            {
                accessorKey: 'superUser',
                header: 'Super User',
                enableEditing: false,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );

    //CREATE action
    const handleCreateUser = async ({ values, table }) => {
        setIsCreatingUser(true)
        let formsdata = new FormData();
        formsdata.append('file', createUser_file);
        formsdata.append('name', createUserData.createUser_name);
        formsdata.append('email', createUserData.createUser_email);
        formsdata.append('password', createUserData.createUser_password);
        formsdata.append('profile_pic', createUser_fileName);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/register`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addnewuser(res.data.user));
            fetchUsersData();
            setIsCreatingUser(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingUser(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveUser = async ({ values, table }) => {
        setIsUpdatingUser(true)
        let formsdata = new FormData();
        formsdata.append('file', editfile);
        formsdata.append('name', editFormData.name);
        formsdata.append('email', user.email);
        formsdata.append('password', editFormData.password);
        formsdata.append('profile_pic', editfileName);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/updateprofile`, formsdata, config);
            toast.success(res.data.message);
            updateUser(res.data.user, ()=>{});
            fetchUsersData();
            setIsUpdatingUser(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingUser(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingUser(true)
        if (window.confirm('Are you sure you want to delete this user?')) {
        //     deleteUser(row.original.id);
            axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteprofile/${row.original.id}`,config)
            .then(res => {
                dispatch(removeuser(row.original.id));
                fetchUsersData();
                setIsDeletingUser(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingUser(false)
                toast.error(err.response.data.message);
            })
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingUsersError
            ?   {
                    color: 'error',
                    children: 'Error loading data',
                }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateUser,
        onEditingRowSave: handleSaveUser,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Create New User</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Name</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleUserDataChange('createUser_name')} value={createUserData.createUser_name} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                    
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Email Address</label>
                                <div className="input-group">
                                    <input type='email' onChange={handleUserDataChange('createUser_email')} value={createUserData.createUser_email} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row py-2" style={{padding: "10%"}}>    
                                <label>Password</label>
                                <div className="input-group">
                                    <input type='password' onChange={handleUserDataChange('createUser_password')} value={createUserData.createUser_password} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-2" style={{padding:'0% 10%'}}>
                                <label>Profile Picture <small>leave empty for default</small></label>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onCreateUserFileChange} className="custom-file-input"/>
                                        <label className="custom-file-label" htmlFor="Customfile">{createUser_fileName}</label>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h3">Edit User</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                    
                        <div className="py-2">
                            <div style={{textAlign: 'center'}}>
                                <img src={editFormData.profilePic} className="img" alt="" style={{width:'300px', height:'250px'}} />
                            </div>
                            <div className="py-2" style={{padding: "10%", display: 'grid'}}>
                                <label>Profile Photo</label>
                                <span>select picture less than 1MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onEditFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editfileName}</label>
                                    </div>
                                </div>
                            </div>     

                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>Name</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('name')} value={editFormData.name} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        
                            <div className="py-2" style={{padding: "10%", display: 'grid'}}>
                                <label>Email Address</label>
                                <div className="input-group">
                                    <input type='email' disabled onChange={handleEditChange('email')} value={editFormData.email} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="py-2" style={{padding: "10%", display: 'grid'}}>
                                <label>Password</label>
                                <div className="input-group">
                                    <input type='password' onChange={handleEditChange('password')} value={editFormData.password} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton disabled={row.original.email===user.email?false:true} onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" disabled={(row.original.superUser==='Yes'?true:false) || (user.superUser?false:true)} onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
              variant="contained"
              disabled={user.superUser?false:true}
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
              }}
            >
              Create New User
            </Button>
        ),
        state: {
            isLoading: isLoadingUsers,
            isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
            showAlertBanner: isLoadingUsersError,
            showProgressBars: isFetchingUsers,
        },
    });

    const fetchUsersData = useCallback( async () => {
        try {
            dispatch(getusersdata());
            await delay(1000);
            setIsFetchingUsers(false)
            setIsLoadingUsers(false)
            let array = []
            store.getState().users.forEach(element => {
                array.push({
                    id: element.id,
                    name: element.name,
                    email: element.email,
                    profilePic: process.env.REACT_APP_API_URL+'/'+element.profilePic,
                    superUser: element.superUser?'Yes':'No',
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
        } catch (error) {
            setIsLoadingUsersError(true)
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchUsersData()    
    }, [fetchUsersData]);

    
    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer></ToastContainer>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Account',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Home;