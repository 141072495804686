import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { isAuth } from '../../helpers/auth';
import { Navigate } from 'react-router-dom';

const ForgetPassword = () => {
    const [formData, setFormData] = useState({
        email: '',
        textChange: 'Submit',
    })

    const { email, textChange } = formData
    // Handle Change from inputs
    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    const handleSubmit = e => {
        e.preventDefault();
        setFormData({...formData, textChange:'Submitting'})
        if(email) {
            axios.put(`${process.env.REACT_APP_API_URL}/user/password/forget`,{ email })
            .then(res => {
                setFormData({
                    ...formData,
                    email: '',
                })
                toast.success(res.data.message);
                setFormData({...formData, textChange:'Submit'})
            }).catch(err => {
                // console.log(err.response);
                toast.error(err.response.data.error);
            })
        } else {
            toast.error('Please Fill All Fields!');
        }
    }

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                {isAuth()? <Navigate to='/'/> : null }
                <ToastContainer></ToastContainer>
                <div className="login-logo">
                    <img src="dist/img/Logo_.png" alt="NIPRM" style={{ width:'350px',height:'90px' }} />
                </div>
  
                <div className="card">
                    <a style={{marginTop:'0.9rem',marginBottom:'0rem'}} className="login-logo" href='/login'><b>Admin</b>Portal</a>
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Enter Email To Reset Your Password</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input type='email' placeholder='Email' onChange={handleChange('email')} value={email} className="form-control" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4" style={{margin: 'auto'}}>
                                    <button type="submit" className="btn btn-primary btn-block">{textChange}</button>
                                </div>
                            </div>
                        </form>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword