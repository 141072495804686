import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getslidersdata, removeslide, updateslide, addnewslide } from '../actions/sliderActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const Sliders = () => {
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)
    
    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editSliderFormData, setEditSliderFormData] = useState({
        title: '',
        description: '',
        slideStatus: '',
    })

    const [editSliderfile, setEditSliderFile] = useState('');
    const [editSliderfileName, setEditSliderFilename] = useState('')

    const onEditSliderFileChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            // toast.error('Please select valid image')
            toast.error('Please select valid image: jpg | jpeg | png')
        } else {
            if(e.target.files[0].size >= 2000000){
                // toast.error('Please select image size less then 64kb')
                toast.error('Please select image size less then 2MB')
            } else {
                setEditSliderFile(e.target.files[0]);
                setEditSliderFilename(e.target.files[0].name);
            }
        }
    };
    
    const handleEditSliderChange = text => e => {
        setEditSliderFormData({...editSliderFormData, [text]: e.target.value})
    }
    // edit Form Fields
    
    // create Form Fields
    const [createSliderData, setCreateSliderData] = useState({
        title: '',
        description: '',
        slideStatus: '',
    })
    const [createSlideImageFile, setCreateSlideImageFile] = useState('');
    const [createSlideImage, setCreateSlideImage] = useState('')

    const handleCreateSliderDataChange = text => e => {
        setCreateSliderData({...createSliderData, [text]: e.target.value})
    }

    const onCreateSliderFileChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Please select valid image: jpg | jpeg | png')
        } else {
            if(e.target.files[0].size >= 2000000){
                toast.error('Please select image size less then 2MB')
            } else {
                setCreateSlideImageFile(e.target.files[0]);
                setCreateSlideImage(e.target.files[0].name);
            }
        }
    };
    // create Form Fields

    // table state
    const [data, setData] = useState([]);
    const [isLoadingSlidersError, setIsLoadingSlidersError] = useState(false);
    const [isLoadingSliders, setIsLoadingSliders ] = useState(true);
    const [isFetchingSliders, setIsFetchingSliders] = useState(true);
    const [isDeletingSliders, setIsDeletingSliders] = useState(false);
    const [isCreatingSliders, setIsCreatingSliders] = useState(false);
    const [isUpdatingSliders, setIsUpdatingSliders] = useState(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'title',
                header: 'Title',
                enableEditing: true,
            },
            {
                accessorKey: 'description',
                header: 'Description',
                enableEditing: true
            },
            {
                accessorKey: 'slideStatus',
                header: 'Slide Status',
                enableEditing: true
            },
            {
                accessorKey: 'slideImage',
                header: 'Slide Image',
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      justifyContent: 'center'
                    }}
                    
                  >
                    <img
                      alt="avatar"
                      height={75}
                      src={renderedCellValue}
                      loading="lazy"
                      style={{  }}
                    />
                  </Box>
                ),
                enableEditing: true,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );

    //CREATE action
    const handleCreateSlider = async ({ values, table }) => {
        setIsCreatingSliders(true)
        let formsdata = new FormData();
        formsdata.append('slideImageFile', createSlideImageFile);
        formsdata.append('title', createSliderData.title);
        formsdata.append('description', createSliderData.description);
        formsdata.append('slideImage', createSlideImage);
        formsdata.append('slideStatus', createSliderData.slideStatus);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sliders/createslide`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addnewslide(res.data.slide));
            fetchSlidersData();
            setCreateSliderData({ title: '', description: '', slideStatus: ''})
            setCreateSlideImage('');
            setCreateSlideImageFile('')
            setIsCreatingSliders(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingSliders(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveSlider = async ({ values, table }) => {
        setIsUpdatingSliders(true)
        console.log(values)
        let formsdata = new FormData();
        formsdata.append('id', values.id);
        formsdata.append('slideImageFile', editSliderfile);
        formsdata.append('title', editSliderFormData.title===''?values.title:editSliderFormData.title);
        formsdata.append('description', editSliderFormData.description===''?values.description:editSliderFormData.description);
        formsdata.append('slideImage', editSliderfileName);
        formsdata.append('slideStatus', editSliderFormData.slideStatus===''?values.slideStatus:editSliderFormData.slideStatus);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sliders/updateslide`,formsdata,config);
            toast.success(res.data.message);
            dispatch(updateslide(res.data.sliderdata));
            fetchSlidersData();
            setEditSliderFormData({ title: '', description: '', slideStatus: ''})
            setEditSliderFile('')
            setEditSliderFilename('');
            setIsUpdatingSliders(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingSliders(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingSliders(true)
        if (window.confirm('Are you sure you want to delete this Slide?')) {
            axios.delete(`${process.env.REACT_APP_API_URL}/sliders/removeslide/${row.original.id}`,config)
            .then(res => {
                dispatch(removeslide(row.original.id));
                fetchSlidersData();
                setIsDeletingSliders(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingSliders(false)
                toast.error(err.response.data.message);
            })    
        }
    }

    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingSlidersError
            ?   {
                    color: 'error',
                    children: 'Error loading data',
                }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateSlider,
        onEditingRowSave: handleSaveSlider,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Create New Slider</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Slider Image &nbsp;</label>
                                <span> select picture less than 2MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onCreateSliderFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{createSlideImage}</label>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Title</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateSliderDataChange('title')} value={createSliderData.title} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Description</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateSliderDataChange('description')} value={createSliderData.description} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <div className="col-md">
                                    <label>Slide Status</label>
                                </div>
                                <div className="d-flex col-md">
                                    <div class="form-check pr-5">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={handleCreateSliderDataChange('slideStatus')} value={true}/>
                                        <label class="form-check-label" for="flexRadioDefault1">Active</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={handleCreateSliderDataChange('slideStatus')} value={false}/>
                                        <label class="form-check-label" for="flexRadioDefault2">InActive</label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h3">Edit Slider</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">
                            <div style={{textAlign: 'center'}}>
                                <img src={row.original.slideImage} className="img" alt="" style={{width:'300px', height:'225px'}} />
                            </div>
                            <div className="py-2" style={{padding: "10%", display: 'grid'}}>
                                <label>Slider Image</label>
                                <span>select picture less than 2MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onEditSliderFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editSliderfileName}</label>
                                    </div>
                                </div>
                            </div>     

                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>Title</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditSliderChange('title')} value={editSliderFormData.title===''?row.original.title:editSliderFormData.title} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>Description</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditSliderChange('description')} value={editSliderFormData.description===''?row.original.description:editSliderFormData.description} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding: "10%"}}>
                                <div className="col-md">
                                    <label>Slide Status</label>
                                </div>
                                <div className="d-flex col-md">
                                    <div class="form-check pr-5">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={handleEditSliderChange('slideStatus')} value={true}/>
                                        <label class="form-check-label" for="flexRadioDefault1">Active</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={handleEditSliderChange('slideStatus')} value={false}/>
                                        <label class="form-check-label" for="flexRadioDefault2">InActive</label>
                                    </div>
                                </div>
                            </div>
                            

                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" disabled={data.length<2?true:false } onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
              variant="contained"
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
              }}
            >
              Create New Slider
            </Button>
        ),
        state: {
            isLoading: isLoadingSliders,
            isSaving: isCreatingSliders || isUpdatingSliders || isDeletingSliders,
            showAlertBanner: isLoadingSlidersError,
            showProgressBars: isFetchingSliders,
        },
    });

    const fetchSlidersData = useCallback( async () => {
        try {
            dispatch(getslidersdata());
            await delay(1000);
            setIsFetchingSliders(false)
            setIsLoadingSliders(false)
            let array = []
            store.getState().sliders.forEach(element => {
                array.push({
                    id: element.id,
                    title: element.title,
                    description: element.description,
                    slideImage: process.env.REACT_APP_API_URL+'/'+element.slideImage,
                    slideStatus: element.slideStatus?"Active":"Inactive",
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
        } catch (error) {
            setIsLoadingSlidersError(true)
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchSlidersData()    
    }, [fetchSlidersData]);

    
    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            {/* <ToastContainer></ToastContainer> */}
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Slider Settings',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Sliders