const initialdata = [
    {
        id: '',
        title: '',
        File: '',
        Date: '',
        createdAt: '',
        updatedAt: ''	
        
    }
]

// eslint-disable-next-line
export default  (announcements = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_ANNOUNCEMENTS':
            announcements = action.payload.data;
            return announcements;
        case 'ADD_NEW_ANNOUNCEMENT':
            let newAnnouncement = action.payload;
            announcements = [ ...announcements, newAnnouncement];
            return announcements;
        case 'UPDATE_ANNOUNCEMENT':
            for (let index = 0; index < announcements.length; index++) {
                if( announcements[index].id === action.payload.id) {
                    announcements[index] = action.payload;
                }
            }
            return announcements
        case 'REMOVE_ANNOUNCEMENT':
            let x=0;
            for (let index = 0; index < announcements.length; index++) {
                if( announcements[index].id === action.payload) {
                    x=index;
                }
            }    
            announcements.splice(x, 1);
            // console.log(categories);
            return announcements
        default:
            return announcements;
    }
}