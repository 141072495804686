const initialdata = [
    {
        id: '',
        name: '',
        email: '',
        image: '',
        position: '',
        department: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (faculties = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_FACULTY':
            faculties = action.payload.data;
            return faculties;
        case 'ADD_NEW_FACULTY':
            let newfaculty = action.payload;
            faculties = [ ...faculties, newfaculty];
            return faculties;
        case 'UPDATE_FACULTY':
            for (let index = 0; index < faculties.length; index++) {
                if( faculties[index].id === action.payload.id) {
                    faculties[index] = action.payload;
                }
            }
            return faculties
        case 'REMOVE_FACULTY':
            let x=0;
            for (let index = 0; index < faculties.length; index++) {
                if( faculties[index].id === action.payload) {
                    x=index;
                }
            }    
            faculties.splice(x, 1);
            return faculties
        default:
            return faculties;
    }
}