import * as api from '../api';   


// get all degreeprograms
export const getdegreeprogramsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetDegreeProgramsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_DEGREE_PROGRAMS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new degreeprogram
export const adddegreeprogram = (NewDegree) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_DEGREE_PROGRAM', payload: NewDegree});
    } catch (error) {
        console.log(error);
    }
}

// update degreeprogram 
export const updatedegreeprogram = (Degree) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_DEGREE_PROGRAM', payload: Degree});
    } catch (error) {
        console.log(error);
    }
}

// remove degreeprogram with id
export const removedegreeprograms = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_DEGREE_PROGRAMS', payload: id});
    } catch (error) {
        console.log(error);
    }
}