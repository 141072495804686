const initialdata = [
    {
        id: '',
        semester: '',
        ttFile: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (timetables = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_STUDENTS_TimeTable':
            timetables = action.payload.data;
            return timetables;
        case 'ADD_NEW_STUDENTS_TimeTable':
            let newtimetable = action.payload;
            timetables = [ ...timetables, newtimetable];
            return timetables;
        case 'UPDATE_STUDENTS_TimeTable':
            for (let index = 0; index < timetables.length; index++) {
                if( timetables[index].id === action.payload.id) {
                    timetables[index] = action.payload;
                }
            }
            return timetables
        case 'REMOVE_STUDENTS_TimeTable':
            let x=0;
            for (let index = 0; index < timetables.length; index++) {
                if( timetables[index].id === action.payload) {
                    x=index;
                }
            }    
            timetables.splice(x, 1);
            return timetables
        default:
            return timetables;
    }
}