import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { geteventsdata, updateevent, removeevent, addnewevent } from '../actions/eventsActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';
import ImageCarousel from '../components/ImageCarousel';

const Events = () => {
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)

    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editFormData, setEditFormData] = useState({
        title:'',
        eventDescription: '',
        dateOccurred: '',
    })

    const [editEventImageFiles, setEditEventImageFiles] = useState([]);
    const [editOldEventImageFiles, setEditOldEventImageFiles] = useState([]);
    
    const handleEditEventImageFilesChange = (event) => {
        let finalFiles = [];
        finalFiles.push(...editEventImageFiles)

        for (let items of event.target.files) {
            finalFiles.push(items)
        }

        setEditEventImageFiles(finalFiles)
    }

    const handleEditEventImageFilesDelete = index => e => {
        e.preventDefault();
        let finalFiles = editEventImageFiles.slice()
        finalFiles.splice(index, 1); // remove 1 element at index i
        setEditEventImageFiles(finalFiles)
    }

    const handleEditEventImageOldFilesDelete = index => e => {
        e.preventDefault();
        let finalFiles = editOldEventImageFiles.slice()
        finalFiles.splice(index, 1); // remove 1 element at index i
        setEditOldEventImageFiles(finalFiles)
    }
    
    
    const handleEditChange = text => e => {
        setEditFormData({...editFormData, [text]: e.target.value})
    }
    // edit Form Fields

    // create Form Fields
    const [createFormData, setCreateFormData] = useState({
        title: '',
        eventDescription: '',
        dateOccurred: ''
    })
    
    const [createEventImageFiles, setCreateEventImageFiles] = useState([]);
    
    const handleCreateEventImageFilesChange = (event) => {
        let finalFiles = [];
        finalFiles.push(...createEventImageFiles)

        for (let items of event.target.files) {
            finalFiles.push(items)
        }

        setCreateEventImageFiles(finalFiles)
    }

    const handleCreateEventImageFilesDelete = index => e => {
        e.preventDefault();
        let finalFiles = createEventImageFiles.slice()
        finalFiles.splice(index, 1); // remove 1 element at index i
        setCreateEventImageFiles(finalFiles)
    }

    const handleCreateChange = text => e => {
        setCreateFormData({...createFormData, [text]: e.target.value})
    }
    // create Form Fields

    // table state
    const [data, setData] = useState([]);
    const [isLoadingDataError, setIsLoadingDataError] = useState(false);
    const [isLoadingData, setIsLoadingData ] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isDeletingData, setIsDeletingData] = useState(false);
    const [isCreatingData, setIsCreatingData] = useState(false);
    const [isUpdatingData, setIsUpdatingData] = useState(false);
    	
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'title',
                header: 'Title',
                enableEditing: true,
            },
            {
                accessorKey: 'eventImages',
                header: 'Event Images',
                enableEditing: true,
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center'
                        }}
                    >
                        {/* implement small image carousel here with caption of images length */}
                        <ImageCarousel images={renderedCellValue} height={150}/>
                    </Box>
                ),
            },
            {
                accessorKey: 'eventDescription',
                header: 'Event Description',
                enableEditing: true,
            },
            {
                accessorKey: 'dateOccurred',
                header: 'Date Occurred',
                enableEditing: true,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );

    //CREATE action
    const handleCreateData = async ({ values, table }) => {
        setIsCreatingData(true)
        // eslint-disable-next-line
        if(createFormData.title=='' || createFormData.dateOccurred=='' || createEventImageFiles==[]){
            toast.error("Title, Date Occurred & Images are Required for the Event");
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
            return
        }
        let filenames = []
        let formsdata = new FormData();
        formsdata.append('title', createFormData.title);
        formsdata.append('eventDescription', createFormData.eventDescription);
        for (let filesdata of createEventImageFiles) {
            formsdata.append("files", filesdata)
            filenames.push(filesdata.name)
        }
        formsdata.append("eventImages", filenames.join(';'));
        formsdata.append('dateOccurred', new Date(createFormData.dateOccurred));

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/createevent`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addnewevent(res.data.event));
            fetchData();
            setCreateFormData({title: '', eventDescription: '', dateOccurred: ''})
            setCreateEventImageFiles([])
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveData = async ({ values, table }) => {
        setIsUpdatingData(true)
        let filenames = [...editOldEventImageFiles]
        let formsdata = new FormData();
        formsdata.append('id', values.id);
        formsdata.append('title', editFormData.title===''?values.title:editFormData.title);
        formsdata.append('eventDescription', editFormData.eventDescription===''?values.eventDescription:editFormData.eventDescription);
        for (let filesdata of editEventImageFiles) {
            formsdata.append("files", filesdata)
            filenames.push(filesdata.name)
        }
        filenames = filenames.join(';')
        formsdata.append("eventImages", filenames);
        formsdata.append('dateOccurred', editFormData.dateOccurred===''?values.dateOccurred:new Date(editFormData.dateOccurred));
        // eslint-disable-next-line
        if(!filenames){
            toast.error("Images are required");
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
            return
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/updateevent`,formsdata,config);
            toast.success(res.data.message);
            dispatch(updateevent(res.data.eventdata));
            fetchData();
            setEditFormData({ title:'', eventDescription: '', dateOccurred: ''})
            setEditEventImageFiles([])
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingData(true)
        if (window.confirm('Are you sure you want to delete this Event?')) {
        
            axios.delete(`${process.env.REACT_APP_API_URL}/events/removeevent/${row.original.id}`,config)
            .then(res => {
                dispatch(removeevent(row.original.id));
                fetchData();
                setIsDeletingData(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingData(false)
                toast.error(err.response.data.message);
            })
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingDataError
            ?   {
                    color: 'error',
                    children: 'Error loading data',
                }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateData,
        onEditingRowSave: handleSaveData,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h4">Add New Event</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Title Of Event</label>
                                <div className="input-group">
                                    <input required type='text' onChange={handleCreateChange('title')} value={createFormData.title} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Event Description</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('eventDescription')} value={createFormData.eventDescription} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Date When Event Occurred</label>
                                <div className="input-group">
                                    <input required aria-label="Date" type="date" onChange={handleCreateChange('dateOccurred')} value={createFormData.dateOccurred} />
                                </div>
                            </div>

                            {/* Multiple Image Input */}
                            <div className="row py-2" style={{padding:'10%'}}>
                                
                                <label>Select Images</label>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input required type="file" multiple={true} onChange={handleCreateEventImageFilesChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{createEventImageFiles.length + " files Selected"}</label>
                                    </div>
                                </div>
                                
                                {/* images preview */}
                                {
                                    createEventImageFiles.length > 0 && (
                                        <>
                                            <div className="container mt-2 justify-content-center" style={{display:'ruby'}}>
                                                {
                                                    createEventImageFiles.map((element,i) => {
                                                        return (
                                                            <div key={i} style={{textAlign: 'center'}}>
                                                                <img src={URL.createObjectURL(element)} onClick={handleCreateEventImageFilesDelete(i)} className="img" alt="" style={{width:'100px', height:'100px', marginLeft: '5px', border: "1px solid black" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h4">Edit Event</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                        <div className="row py-2" style={{padding: "5%"}}>
                                <label>Title Of Event</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('title')} value={editFormData.title===''?row.original.title:editFormData.title} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding: "5%"}}>
                                <label>Event Description</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('eventDescription')} value={editFormData.eventDescription===''?row.original.eventDescription:editFormData.eventDescription} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding: "5%"}}>
                                <label>Date When Event Occurred</label>
                                <div className="input-group">
                                    <div className="col-md">
                                        <input aria-label="Date" type="date" onChange={handleEditChange('dateOccurred')} value={editFormData.dateOccurred} />
                                    </div>
                                    <div className="col-md">
                                        <label>Current: &nbsp;</label>
                                        <span>{row.original.dateOccurred}</span>
                                    </div>
                                </div>
                            </div>

                             {/* Multiple Image Input */}
                             <div className="row py-2" style={{padding:'5%'}}>
                                
                                <label>Select Images</label>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" multiple={true} onChange={handleEditEventImageFilesChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editEventImageFiles.length + " files Selected"}</label>
                                    </div>
                                </div>
                                
                                {/* images preview */}
                                {
                                    
                                    (row.original.eventImages.length > 0 || editEventImageFiles.length > 0) && (
                                        <>
                                            <div className="container mt-2 justify-content-center" style={{display:'ruby'}}>
                                                {
                                                    editOldEventImageFiles.map((element,i) => {
                                                        return (
                                                            <div key={i} style={{textAlign: 'center'}}>
                                                                <img src={element} onClick={handleEditEventImageOldFilesDelete(i)} className="img" alt="" style={{width:'100px', height:'100px', marginLeft: '5px', border: "1px solid black" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    editEventImageFiles.map((element,i) => {
                                                        return (
                                                            <div key={i} style={{textAlign: 'center'}}>
                                                                <img src={URL.createObjectURL(element)} onClick={handleEditEventImageFilesDelete(i)} className="img" alt="" style={{width:'100px', height:'100px', marginLeft: '5px', border: "1px solid black" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => { table.setEditingRow(row);setEditOldEventImageFiles(row.original.eventImages) } }>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
              variant="contained"
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
              }}
            >
              Add New Event
            </Button>
        ),
        state: {
            isLoading: isLoadingData,
            isSaving: isCreatingData || isUpdatingData || isDeletingData,
            showAlertBanner: isLoadingDataError,
            showProgressBars: isFetchingData,
        },
    });

    const fetchData = useCallback( async () => {
        try {
            dispatch(geteventsdata());
            await delay(1000);
            setIsFetchingData(false)
            setIsLoadingData(false)
            let array = []
            store.getState().events.forEach(element => {
                let images = element.eventImages.split(";")
                images = images.map(i => process.env.REACT_APP_API_URL+'/'+i);
                array.push({
                    id: element.id,
                    title: element.title,
                    eventImages: images,
                    eventDescription: element.eventDescription,
                    dateOccurred: element.dateOccurred?.substring(0, 10),
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
        } catch (error) {
            setIsLoadingDataError(true)
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchData()    
    }, [fetchData]);

    
    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer></ToastContainer>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Events',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Events
