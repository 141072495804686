import React, { useEffect, useState} from 'react'
import { isAuth, getCookie } from '../helpers/auth';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { useSelector } from 'react-redux';
import { updateprincipalsmsgdata } from '../actions/principalmsgActions';
import { useDispatch } from 'react-redux';

// CKEditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, AccessibilityHelp, Alignment, Autoformat, AutoLink, Autosave, BlockQuote, Bold, Essentials, FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, Highlight, HorizontalLine, Indent, IndentBlock, Italic, Link, Paragraph, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Subscript, Superscript, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextTransformation, Underline, Undo,Base64UploadAdapter,CKFinder,CKFinderUploadAdapter,CloudServices,Image,ImageCaption,ImageResize,ImageStyle,ImageToolbar,ImageUpload,PictureEditing,List,MediaEmbed,Mention,PasteFromOffice } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const PrincipalMsg = () => {
    const dispatch = useDispatch();
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    const PrincipalsMsgdata = useSelector((state) => state.principalsmsg);
    
    const [formData, setFormData] = useState({
        id: PrincipalsMsgdata.id,
        principalName: PrincipalsMsgdata.principalName,
        principalImage: PrincipalsMsgdata.principalImage,
    });
    const { id, principalName, principalImage } = formData

    const [MessageData, setMessageData] = useState({
        Message: PrincipalsMsgdata.Message
    })
    const { Message } = MessageData

    useEffect(() => {
        setFormData({
            id: PrincipalsMsgdata.id,
            principalName: PrincipalsMsgdata.principalName,
            principalImage: process.env.REACT_APP_API_URL+'/'+PrincipalsMsgdata.principalImage,
        })
        setMessageData({
            Message: PrincipalsMsgdata.Message
        })
    },[PrincipalsMsgdata])

    
    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    const handleMsgChange = (e,editor) => {
        const data = editor.getData();
        setMessageData({...MessageData, Message: data})
        // console.log(formData)
        // console.log(MessageData)
    }
    
    const [principalImageFile, setPrincipalImageFile] = useState('');
    const [principalImages, setPrincipalImages] = useState('')

    const onFileChange = e => {
        if (!e.target.files[0].name.toLowerCase().match(/\.(jpeg|jpg|png)$/)) {
            toast.error('Please select valid document')
        } else {
            if(e.target.files[0].size >= 2000000){
                toast.error('Please select file size less then 2MB')
            } else {
                setPrincipalImageFile(e.target.files[0]);
                setPrincipalImages(e.target.files[0].name);
            }
        }
    };

    // send data to backend
    const handleSubmit = async e => {
        e.preventDefault();
        let formsdata = new FormData();
        formsdata.append('id', id);
        formsdata.append('principalName', principalName);
        formsdata.append('principalImage', principalImages);
        formsdata.append('principalImageFile', principalImageFile);
        formsdata.append('Message', Message);
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/principalmsg/updateprincipalmsg`, formsdata, config);
            toast.success(res.data.message);
            dispatch(updateprincipalsmsgdata(res.data.principalmsg));
            setFormData({
                id: PrincipalsMsgdata.id,
                principalName: PrincipalsMsgdata.principalName,
                Message: PrincipalsMsgdata.Message,
                principalImage: process.env.REACT_APP_API_URL+'/'+PrincipalsMsgdata.principalImage,
            })
            setPrincipalImages('')
            setPrincipalImageFile('')
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
        }
    }

    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Principal Message',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <form onSubmit={handleSubmit}>
                            <div className="wrapper bg-white border">
                                <div className="py-2">
                                    
                                    <div className="row py-2" style={{padding: "10%"}}>
                                        <label>Principal's Name</label>
                                        <div className="input-group">
                                            <input type='text' onChange={handleChange('principalName')} value={principalName} className="bg-light form-control" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-pen"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row py-2" style={{padding: "10%"}}> */}
                                    <div className="row py-2" style={{padding:"10%"}}> 
                                        <img src={principalImage} className="img" alt="" style={{width:'300px', height:'250px'}} />
                                    </div>
                                    <div className="row py-2" style={{padding:'10%'}}> 
                                        <label>Principal's Image &nbsp;</label>
                                        <span> select picture less than 1MB</span>
                                        <div className="custom-file">
                                            <div className="custom-file">
                                                <input type="file" onChange={onFileChange} className="custom-file-input" id="Customfile" />
                                                <label className="custom-file-label" htmlFor="Customfile">{principalImages}</label>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    
                                    {/* </div> */}

                                    <div className="row py-2" style={{padding: "10%"}}>
                                        <div className=""></div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'findAndReplace',
                                                        'selectAll',
                                                        '|',
                                                        'heading',
                                                        '|',
                                                        'fontSize',
                                                        'fontFamily',
                                                        'fontColor',
                                                        'fontBackgroundColor',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikethrough',
                                                        'subscript',
                                                        'superscript',
                                                        '|',
                                                        'specialCharacters',
                                                        'horizontalLine',
                                                        'link',
                                                        'insertTable',
                                                        'uploadImage',
                                                        'mediaEmbed',
                                                        'highlight',
                                                        'blockQuote',
                                                        '|',
                                                        'alignment',
                                                        '|',
                                                        'indent',
                                                        'outdent',
                                                        '|',
                                                        'accessibilityHelp'
                                                    ],
                                                    shouldNotGroupWhenFull: false
                                                },
                                                plugins: [
                                                    AccessibilityHelp,
                                                    Alignment,
                                                    Autoformat,
                                                    AutoLink,
                                                    Autosave,
                                                    BlockQuote,
                                                    Bold,
                                                    Essentials,
                                                    FindAndReplace,
                                                    FontBackgroundColor,
                                                    FontColor,
                                                    FontFamily,
                                                    FontSize,
                                                    Heading,
                                                    Highlight,
                                                    HorizontalLine,
                                                    Indent,
                                                    IndentBlock,
                                                    Italic,
                                                    Link,
                                                    Paragraph,
                                                    SelectAll,
                                                    SpecialCharacters,
                                                    SpecialCharactersArrows,
                                                    SpecialCharactersCurrency,
                                                    SpecialCharactersEssentials,
                                                    SpecialCharactersLatin,
                                                    SpecialCharactersMathematical,
                                                    SpecialCharactersText,
                                                    Strikethrough,
                                                    Subscript,
                                                    Superscript,
                                                    Table,
                                                    TableCaption,
                                                    TableCellProperties,
                                                    TableColumnResize,
                                                    TableProperties,
                                                    TableToolbar,
                                                    TextTransformation,
                                                    Underline,
                                                    Undo,
                                                    CKFinder,
                                                    CKFinderUploadAdapter,
                                                    CloudServices,
                                                    Image,
                                                    ImageCaption,
                                                    ImageResize,
                                                    ImageStyle,
                                                    ImageToolbar,
                                                    ImageUpload,
                                                    Base64UploadAdapter,
                                                    List,
                                                    MediaEmbed,
                                                    Mention,
                                                    PasteFromOffice,
                                                    PictureEditing,    
                                                ],
                                                fontFamily: {
                                                    supportAllValues: true
                                                },
                                                fontSize: {
                                                    options: [10, 12, 14, 'default', 18, 20, 22],
                                                    supportAllValues: true
                                                },
                                                heading: {
                                                    options: [
                                                        {
                                                            model: 'paragraph',
                                                            title: 'Paragraph',
                                                            class: 'ck-heading_paragraph'
                                                        },
                                                        {
                                                            model: 'heading1',
                                                            view: 'h1',
                                                            title: 'Heading 1',
                                                            class: 'ck-heading_heading1'
                                                        },
                                                        {
                                                            model: 'heading2',
                                                            view: 'h2',
                                                            title: 'Heading 2',
                                                            class: 'ck-heading_heading2'
                                                        },
                                                        {
                                                            model: 'heading3',
                                                            view: 'h3',
                                                            title: 'Heading 3',
                                                            class: 'ck-heading_heading3'
                                                        },
                                                        {
                                                            model: 'heading4',
                                                            view: 'h4',
                                                            title: 'Heading 4',
                                                            class: 'ck-heading_heading4'
                                                        },
                                                        {
                                                            model: 'heading5',
                                                            view: 'h5',
                                                            title: 'Heading 5',
                                                            class: 'ck-heading_heading5'
                                                        },
                                                        {
                                                            model: 'heading6',
                                                            view: 'h6',
                                                            title: 'Heading 6',
                                                            class: 'ck-heading_heading6'
                                                        }
                                                    ]
                                                },
                                                image: {
                                                    resizeOptions: [
                                                        {
                                                            name: 'resizeImage:original',
                                                            label: 'Default image width',
                                                            value: null,
                                                        },
                                                        {
                                                            name: 'resizeImage:50',
                                                            label: '50% page width',
                                                            value: '50',
                                                        },
                                                        {
                                                            name: 'resizeImage:75',
                                                            label: '75% page width',
                                                            value: '75',
                                                        },
                                                    ],
                                                    toolbar: [
                                                        'imageTextAlternative',
                                                        'toggleImageCaption',
                                                        '|',
                                                        'imageStyle:inline',
                                                        'imageStyle:wrapText',
                                                        'imageStyle:breakText',
                                                        '|',
                                                        'resizeImage',
                                                    ],
                                                },
                                                link: {
                                                    addTargetToExternalLinks: true,
                                                    defaultProtocol: 'https://',
                                                    decorators: {
                                                        toggleDownloadable: {
                                                            mode: 'manual',
                                                            label: 'Downloadable',
                                                            attributes: {
                                                                download: 'file'
                                                            }
                                                        }
                                                    }
                                                },
                                                placeholder: 'Type or paste your content here!',
                                                table: {
                                                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                                                }
                                            }}
                                            data={ Message }
                                            onChange={handleMsgChange}
                                        />
                                    </div>
                                </div>
                                <div className="py-2 text-center border-bottom"> 
                                    <button type='submit' className="btn btn-secondary mr-3">Update Principal's Message</button> 
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default PrincipalMsg
