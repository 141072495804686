const initialdata = [
    {
        id:'',
        degTitle: '',
        degImage: '',
        degProspectus: '',
        degFeePerSemesterMerit: '',
        degFeePerSemesterSelf: '',
        degFeePerSemesterOverseas: '',
        degLevel: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (degreeprograms = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_DEGREE_PROGRAMS':
            degreeprograms = action.payload.data;
            return degreeprograms;
        case 'ADD_NEW_DEGREE_PROGRAM':
            let newslide = action.payload;
            degreeprograms = [ ...degreeprograms, newslide];
            return degreeprograms;
        case 'UPDATE_DEGREE_PROGRAM':
            for (let index = 0; index < degreeprograms.length; index++) {
                if( degreeprograms[index].id === action.payload.id) {
                    degreeprograms[index] = action.payload;
                }
            }
            return degreeprograms
        case 'REMOVE_DEGREE_PROGRAMS':
            let x=0;
            for (let index = 0; index < degreeprograms.length; index++) {
                if( degreeprograms[index].id === action.payload) {
                    x=index;
                }
            }    
            degreeprograms.splice(x, 1);
            // console.log(categories);
            return degreeprograms
        default:
            return degreeprograms;
    }
}