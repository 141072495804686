import * as api from '../api';   


// get all jobs
export const getjobsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetJobsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_JOBS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new job
export const addnewjob = (NewJob) => async (dispatch) => {
    try {
        
        dispatch({ type: 'ADD_NEW_JOB', payload: NewJob});
    } catch (error) {
        console.log(error);
    }
}

// update job
export const updatejob = (Job) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_JOB', payload: Job});
    } catch (error) {
        console.log(error);
    }
}

// remove job with id
export const removejob = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_JOB', payload: id});
    } catch (error) {
        console.log(error);
    }
}