import React from 'react'
import { isAuth, signout } from '../helpers/auth';
import {Link} from 'react-router-dom';

const Header = () => {

    const handleSubmit = e => {
        e.preventDefault();
        if(isAuth()){
            signout();
            setTimeout(function () {
                window.location.reload(false);    
            }, 1000);
        }
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="/acount" role="button"><i className="fas fa-bars" /></a>
                </li>
            </ul>
            
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="/" className="nav-link">Account</a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/" className="disabledCursor nav-link"  onClick={handleSubmit}>Sign Out</Link>
                </li>    
            </ul>
        </nav>
    )
}

export default Header