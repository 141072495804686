import React, { useEffect } from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles.css';

import Home from './screens/Home';
import Login from './screens/Auth/Login';
import ForgetPassword from './screens/Auth/ForgetPassword';
import Reset from './screens/Auth/Reset';
import Admissions from './screens/Admissions';
import DegreePrograms from './screens/DegreePrograms';
import Events from './screens/Events';
import ExamSchedules from './screens/ExamSchedules';
import Faculty from './screens/Faculty';
import Jobs from './screens/Jobs';
import MiscellaneousFee from './screens/MiscellaneousFee';
import PrincipalMsg from './screens/PrincipalMsg';
import Sliders from './screens/Sliders';
import StudentResults from './screens/StudentResults';
import StudentTimeTable from './screens/StudentTimeTable';
import Announcements from './screens/Announcements';
import Departments from './screens/Departments';
import Hospitals from './screens/Hospitals';
import Facilities from './screens/Facilities';

import { useDispatch } from 'react-redux';
import { getadmissionsdata } from './actions/admissionActions';
import { getprincipalsmsgdata } from './actions/principalmsgActions';

const App = () => {
    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getadmissionsdata());
        dispatch(getprincipalsmsgdata())
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' Component={Home} />
                <Route path='/login' Component={Login} />
                <Route path='/admin-password-forget' Component={ForgetPassword} />
                <Route path='/admin-password-reset/:token' Component={Reset} />
                <Route path='/admissions' Component={Admissions} />
                <Route path='/degreeprograms' Component={DegreePrograms} />
                <Route path='/events' Component={Events} />
                <Route path='/examschedules' Component={ExamSchedules} />
                <Route path='/faculty' Component={Faculty} />
                <Route path='/jobs' Component={Jobs} />
                <Route path='/miscellaneousfees' Component={MiscellaneousFee} />
                <Route path='/principalmessage' Component={PrincipalMsg} />
                <Route path='/sliders' Component={Sliders} />
                <Route path='/studentresults' Component={StudentResults} />
                <Route path='/studentstimetable' Component={StudentTimeTable} />
                <Route path='/announcements' Component={Announcements} />
                <Route path='/departments' Component={Departments} />
                <Route path='/hospitals' Component={Hospitals} />
                <Route path='/facilities' Component={Facilities} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;