const initialdata = {
    id: '',
    principalName: '',
    Message: '',
    principalImage: '',
    createdAt: '',
    updatedAt: ''
}

// eslint-disable-next-line
export default  (principalsmsg = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_PRINCIPALS_MSG':
            principalsmsg = action.payload.data;
            return principalsmsg;
        case 'UPDATE_PRINCIPALS_MSG':
            principalsmsg = action.payload.data;
            return principalsmsg;
        default:
            return principalsmsg;
    }
}