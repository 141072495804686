const initialdata = [
    {
        id: '',
        name: '',
        description: ''
    }
]

// eslint-disable-next-line
export default  (hospitals = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_Hospitals':
            hospitals = action.payload.data;
            return hospitals;
        case 'ADD_NEW_Hospital':
            let newfee = action.payload;
            hospitals = [ ...hospitals, newfee];
            return hospitals;
        case 'UPDATE_Hospital':
            for (let index = 0; index < hospitals.length; index++) {
                if( hospitals[index].id === action.payload.id) {
                    hospitals[index] = action.payload;
                }
            }
            return hospitals
        case 'REMOVE_Hospital':
            let x=0;
            for (let index = 0; index < hospitals.length; index++) {
                if( hospitals[index].id === action.payload) {
                    x=index;
                }
            }    
            hospitals.splice(x, 1);
            // console.log(categories);
            return hospitals
        default:
            return hospitals;
    }
}