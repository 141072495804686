import React, { useEffect, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getdepartmentsdata, updatedepartment, removedepartment, adddepartment } from '../actions/departmentActions';

// CKEditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, AccessibilityHelp, Alignment, Autoformat, AutoLink, Autosave, BlockQuote, Bold, Essentials, FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, Highlight, HorizontalLine, Indent, IndentBlock, Italic, Link, Paragraph, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Subscript, Superscript, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextTransformation, Underline, Undo,Base64UploadAdapter,CKFinder,CKFinderUploadAdapter,CloudServices,Image,ImageCaption,ImageResize,ImageStyle,ImageToolbar,ImageUpload,PictureEditing,List,MediaEmbed,Mention,PasteFromOffice } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

// Material UI
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Departments = () => {
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSelectorChange = e => {
        console.log(e.target.value)
        // eslint-disable-next-line
        if(e.target.value == -1){
            setEditFormData({id: '', departmentName: '', })
            setEditformDescriptionData({description: ''})
        } else {
            // eslint-disable-next-line
            const selectedDept = data.find(dept => dept.id == e.target.value)
            setEditFormData({id: selectedDept.id, departmentName: selectedDept.name })
            setEditformDescriptionData({description: selectedDept.description})
        }
    }

    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)

    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editformData, setEditFormData] = useState({
        id: '',
        departmentName: '',
    });
    
    const [editformDescriptionData, setEditformDescriptionData] = useState({
        description: ''
    })

    const handleEditChange = text => e => {
        setEditFormData({...editformData, [text]: e.target.value})
    }

    const handleEditDescriptionChange = (e,editor) => {
        const data = editor.getData();
        setEditformDescriptionData({...editformDescriptionData, description: data})
    }
    // edit Form Fields

    // create Form Fields
    const [createformData, setCreateFormData] = useState({
        departmentName: ''
    });

    const [createformDescriptionData, setCreateformDescriptionData] = useState({
        description: ''
    })
    
    const handleCreateChange = text => e => {
        setCreateFormData({...createformData, [text]: e.target.value})
    }

    const handleCreateDescriptionChange = (e,editor) => {
        const data = editor.getData();
        setCreateformDescriptionData({...createformDescriptionData, description: data})
    }
    // create Form Fields


    
    
    // send data to backend
    const handleSubmitRemove = async e => {
        if (window.confirm('Are you sure you want to delete this Department?')) {
        
            axios.delete(`${process.env.REACT_APP_API_URL}/departments/remove/${editformData.id}`,config)
            .then(res => {
                dispatch(removedepartment(editformData.id));
                fetchData();
                toast.success(res.data.message);
                setEditFormData({id: '', departmentName: ''})
                setEditformDescriptionData({description: ''})

            }).catch(err => {     
                toast.error(err.response.data.message);
            })
        }
    }
    
    const handleSubmitUpdate = async e => {
        let formsdata = new FormData();
        formsdata.append('id', editformData.id);
        formsdata.append('name', editformData.departmentName);
        formsdata.append('description', editformDescriptionData.description);
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/departments/update`, formsdata, config);
            toast.success(res.data.message);
            dispatch(updatedepartment(res.data.department));
            setEditFormData({ id: '', departmentName: '', });
            setEditformDescriptionData({ description: '', })
            fetchData();
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
        }
    }
    
    const handleSubmitCreate = async e => {
        let formsdata = new FormData();
        formsdata.append('name', createformData.departmentName);
        formsdata.append('description', createformDescriptionData.description);
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/departments/create`, formsdata, config);
            toast.success(res.data.message);
            dispatch(adddepartment(res.data.department));
            setCreateFormData({ departmentName: '', });
            setCreateformDescriptionData({ description: '', })
            fetchData();
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
        }
    }

    // Fetch Data
    const fetchData = useCallback( async () => {
        try {
            dispatch(getdepartmentsdata());
            await delay(1000);
            let array = []
            store.getState().departments.forEach(element => {
                array.push({
                    id: element.id,
                    name: element.name,
                    description: element.description,
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.error);
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchData()    
    }, [fetchData]);

    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer />
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Departments',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <div className="wrapper bg-white border">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Edit" {...a11yProps(0)} />
                                    <Tab label="Create" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <form>
                                    <div className="py-2">
                                        
                                        <div className="row py-2" style={{padding: '10%'}}>
                                            <label>Department</label>
                                            <select onChange={handleSelectorChange} defaultValue={-1} className="bg-light form-control">
                                                <option value={-1}>Select Department</option>
                                                {data.map((dept,i)=>{
                                                    return(
                                                        <option key={i} value={dept.id}>{dept.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        
                                        <div className="row py-2" style={{padding: "10%"}}>
                                            <label>Department Name</label>
                                            <div className="input-group">
                                                <input type='text' onChange={handleEditChange('departmentName')} value={editformData.departmentName} className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-pen"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* </div> */}

                                        <div className="row py-2" style={{padding: "10%"}}>
                                            <label>Department Description</label>
                                            <div style={{width: "100%"}}>
                                                <CKEditor
                                                    editor={ ClassicEditor }
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'undo',
                                                                'redo',
                                                                '|',
                                                                'findAndReplace',
                                                                'selectAll',
                                                                '|',
                                                                'heading',
                                                                '|',
                                                                'fontSize',
                                                                'fontFamily',
                                                                'fontColor',
                                                                'fontBackgroundColor',
                                                                '|',
                                                                'bold',
                                                                'italic',
                                                                'underline',
                                                                'strikethrough',
                                                                'subscript',
                                                                'superscript',
                                                                '|',
                                                                'specialCharacters',
                                                                'horizontalLine',
                                                                'link',
                                                                'insertTable',
                                                                'uploadImage',
                                                                'mediaEmbed',
                                                                'highlight',
                                                                'blockQuote',
                                                                '|',
                                                                'alignment',
                                                                '|',
                                                                'indent',
                                                                'outdent',
                                                                '|',
                                                                'accessibilityHelp'
                                                            ],
                                                            shouldNotGroupWhenFull: false
                                                        },
                                                        plugins: [
                                                            AccessibilityHelp,
                                                            Alignment,
                                                            Autoformat,
                                                            AutoLink,
                                                            Autosave,
                                                            BlockQuote,
                                                            Bold,
                                                            Essentials,
                                                            FindAndReplace,
                                                            FontBackgroundColor,
                                                            FontColor,
                                                            FontFamily,
                                                            FontSize,
                                                            Heading,
                                                            Highlight,
                                                            HorizontalLine,
                                                            Indent,
                                                            IndentBlock,
                                                            Italic,
                                                            Link,
                                                            Paragraph,
                                                            SelectAll,
                                                            SpecialCharacters,
                                                            SpecialCharactersArrows,
                                                            SpecialCharactersCurrency,
                                                            SpecialCharactersEssentials,
                                                            SpecialCharactersLatin,
                                                            SpecialCharactersMathematical,
                                                            SpecialCharactersText,
                                                            Strikethrough,
                                                            Subscript,
                                                            Superscript,
                                                            Table,
                                                            TableCaption,
                                                            TableCellProperties,
                                                            TableColumnResize,
                                                            TableProperties,
                                                            TableToolbar,
                                                            TextTransformation,
                                                            Underline,
                                                            Undo,
                                                            CKFinder,
                                                            CKFinderUploadAdapter,
                                                            CloudServices,
                                                            Image,
                                                            ImageCaption,
                                                            ImageResize,
                                                            ImageStyle,
                                                            ImageToolbar,
                                                            ImageUpload,
                                                            Base64UploadAdapter,
                                                            List,
                                                            MediaEmbed,
                                                            Mention,
                                                            PasteFromOffice,
                                                            PictureEditing,    
                                                        ],
                                                        fontFamily: {
                                                            supportAllValues: true
                                                        },
                                                        fontSize: {
                                                            options: [10, 12, 14, 'default', 18, 20, 22],
                                                            supportAllValues: true
                                                        },
                                                        heading: {
                                                            options: [
                                                                {
                                                                    model: 'paragraph',
                                                                    title: 'Paragraph',
                                                                    class: 'ck-heading_paragraph'
                                                                },
                                                                {
                                                                    model: 'heading1',
                                                                    view: 'h1',
                                                                    title: 'Heading 1',
                                                                    class: 'ck-heading_heading1'
                                                                },
                                                                {
                                                                    model: 'heading2',
                                                                    view: 'h2',
                                                                    title: 'Heading 2',
                                                                    class: 'ck-heading_heading2'
                                                                },
                                                                {
                                                                    model: 'heading3',
                                                                    view: 'h3',
                                                                    title: 'Heading 3',
                                                                    class: 'ck-heading_heading3'
                                                                },
                                                                {
                                                                    model: 'heading4',
                                                                    view: 'h4',
                                                                    title: 'Heading 4',
                                                                    class: 'ck-heading_heading4'
                                                                },
                                                                {
                                                                    model: 'heading5',
                                                                    view: 'h5',
                                                                    title: 'Heading 5',
                                                                    class: 'ck-heading_heading5'
                                                                },
                                                                {
                                                                    model: 'heading6',
                                                                    view: 'h6',
                                                                    title: 'Heading 6',
                                                                    class: 'ck-heading_heading6'
                                                                }
                                                            ]
                                                        },
                                                        image: {
                                                            resizeOptions: [
                                                                {
                                                                    name: 'resizeImage:original',
                                                                    label: 'Default image width',
                                                                    value: null,
                                                                },
                                                                {
                                                                    name: 'resizeImage:50',
                                                                    label: '50% page width',
                                                                    value: '50',
                                                                },
                                                                {
                                                                    name: 'resizeImage:75',
                                                                    label: '75% page width',
                                                                    value: '75',
                                                                },
                                                            ],
                                                            toolbar: [
                                                                'imageTextAlternative',
                                                                'toggleImageCaption',
                                                                '|',
                                                                'imageStyle:inline',
                                                                'imageStyle:wrapText',
                                                                'imageStyle:breakText',
                                                                '|',
                                                                'resizeImage',
                                                            ],
                                                        },
                                                        link: {
                                                            addTargetToExternalLinks: true,
                                                            defaultProtocol: 'https://',
                                                            decorators: {
                                                                toggleDownloadable: {
                                                                    mode: 'manual',
                                                                    label: 'Downloadable',
                                                                    attributes: {
                                                                        download: 'file'
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        placeholder: 'Type or paste your content here!',
                                                        table: {
                                                            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                                                        }
                                                    }}
                                                    data={ editformDescriptionData.description }
                                                    onChange={handleEditDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2 text-center"> 
                                        <button type='button' onClick={handleSubmitUpdate} className="btn btn-secondary mr-3">Update Department</button> 
                                        <button type='button' onClick={handleSubmitRemove} className="btn btn-secondary mr-3">Delete Department</button> 
                                    </div>
                                </form>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <form>
                                    <div className="py-2">
                                        
                                        <div className="row py-2" style={{padding: "10%"}}>
                                            <label>Department Name</label>
                                            <div className="input-group">
                                                <input type='text' onChange={handleCreateChange('departmentName')} value={ createformData.departmentName } className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-pen"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row py-2" style={{padding: "10%"}}>
                                            <label>Department Description</label>
                                            <div style={{width: "100%"}}>
                                                <CKEditor
                                                    editor={ ClassicEditor }
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'undo',
                                                                'redo',
                                                                '|',
                                                                'findAndReplace',
                                                                'selectAll',
                                                                '|',
                                                                'heading',
                                                                '|',
                                                                'fontSize',
                                                                'fontFamily',
                                                                'fontColor',
                                                                'fontBackgroundColor',
                                                                '|',
                                                                'bold',
                                                                'italic',
                                                                'underline',
                                                                'strikethrough',
                                                                'subscript',
                                                                'superscript',
                                                                '|',
                                                                'specialCharacters',
                                                                'horizontalLine',
                                                                'link',
                                                                'insertTable',
                                                                'uploadImage',
                                                                'mediaEmbed',
                                                                'highlight',
                                                                'blockQuote',
                                                                '|',
                                                                'alignment',
                                                                '|',
                                                                'indent',
                                                                'outdent',
                                                                '|',
                                                                'accessibilityHelp'
                                                            ],
                                                            shouldNotGroupWhenFull: false
                                                        },
                                                        plugins: [
                                                            AccessibilityHelp,
                                                            Alignment,
                                                            Autoformat,
                                                            AutoLink,
                                                            Autosave,
                                                            BlockQuote,
                                                            Bold,
                                                            Essentials,
                                                            FindAndReplace,
                                                            FontBackgroundColor,
                                                            FontColor,
                                                            FontFamily,
                                                            FontSize,
                                                            Heading,
                                                            Highlight,
                                                            HorizontalLine,
                                                            Indent,
                                                            IndentBlock,
                                                            Italic,
                                                            Link,
                                                            Paragraph,
                                                            SelectAll,
                                                            SpecialCharacters,
                                                            SpecialCharactersArrows,
                                                            SpecialCharactersCurrency,
                                                            SpecialCharactersEssentials,
                                                            SpecialCharactersLatin,
                                                            SpecialCharactersMathematical,
                                                            SpecialCharactersText,
                                                            Strikethrough,
                                                            Subscript,
                                                            Superscript,
                                                            Table,
                                                            TableCaption,
                                                            TableCellProperties,
                                                            TableColumnResize,
                                                            TableProperties,
                                                            TableToolbar,
                                                            TextTransformation,
                                                            Underline,
                                                            Undo,
                                                            CKFinder,
                                                            CKFinderUploadAdapter,
                                                            CloudServices,
                                                            Image,
                                                            ImageCaption,
                                                            ImageResize,
                                                            ImageStyle,
                                                            ImageToolbar,
                                                            ImageUpload,
                                                            Base64UploadAdapter,
                                                            List,
                                                            MediaEmbed,
                                                            Mention,
                                                            PasteFromOffice,
                                                            PictureEditing,    
                                                        ],
                                                        fontFamily: {
                                                            supportAllValues: true
                                                        },
                                                        fontSize: {
                                                            options: [10, 12, 14, 'default', 18, 20, 22],
                                                            supportAllValues: true
                                                        },
                                                        heading: {
                                                            options: [
                                                                {
                                                                    model: 'paragraph',
                                                                    title: 'Paragraph',
                                                                    class: 'ck-heading_paragraph'
                                                                },
                                                                {
                                                                    model: 'heading1',
                                                                    view: 'h1',
                                                                    title: 'Heading 1',
                                                                    class: 'ck-heading_heading1'
                                                                },
                                                                {
                                                                    model: 'heading2',
                                                                    view: 'h2',
                                                                    title: 'Heading 2',
                                                                    class: 'ck-heading_heading2'
                                                                },
                                                                {
                                                                    model: 'heading3',
                                                                    view: 'h3',
                                                                    title: 'Heading 3',
                                                                    class: 'ck-heading_heading3'
                                                                },
                                                                {
                                                                    model: 'heading4',
                                                                    view: 'h4',
                                                                    title: 'Heading 4',
                                                                    class: 'ck-heading_heading4'
                                                                },
                                                                {
                                                                    model: 'heading5',
                                                                    view: 'h5',
                                                                    title: 'Heading 5',
                                                                    class: 'ck-heading_heading5'
                                                                },
                                                                {
                                                                    model: 'heading6',
                                                                    view: 'h6',
                                                                    title: 'Heading 6',
                                                                    class: 'ck-heading_heading6'
                                                                }
                                                            ]
                                                        },
                                                        image: {
                                                            resizeOptions: [
                                                                {
                                                                    name: 'resizeImage:original',
                                                                    label: 'Default image width',
                                                                    value: null,
                                                                },
                                                                {
                                                                    name: 'resizeImage:50',
                                                                    label: '50% page width',
                                                                    value: '50',
                                                                },
                                                                {
                                                                    name: 'resizeImage:75',
                                                                    label: '75% page width',
                                                                    value: '75',
                                                                },
                                                            ],
                                                            toolbar: [
                                                                'imageTextAlternative',
                                                                'toggleImageCaption',
                                                                '|',
                                                                'imageStyle:inline',
                                                                'imageStyle:wrapText',
                                                                'imageStyle:breakText',
                                                                '|',
                                                                'resizeImage',
                                                            ],
                                                        },
                                                        link: {
                                                            addTargetToExternalLinks: true,
                                                            defaultProtocol: 'https://',
                                                            decorators: {
                                                                toggleDownloadable: {
                                                                    mode: 'manual',
                                                                    label: 'Downloadable',
                                                                    attributes: {
                                                                        download: 'file'
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        placeholder: 'Type or paste your content here!',
                                                        table: {
                                                            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                                                        }
                                                    }}
                                                    data={ createformDescriptionData.description }
                                                    onChange={handleCreateDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2 text-center"> 
                                        <button type='button' onClick={handleSubmitCreate} className="btn btn-secondary mr-3">Create Department</button> 
                                    </div>
                                </form>
                            </CustomTabPanel>
                        </Box>
                        </div>
                    </div>
                </div>
            </div>
            
            <br/>
            <br/>
            <Footer></Footer>
        </div>
    )
}

export default Departments