import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authenticate, isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const Login = () => {
    let history = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        textChange: 'Sign In'
    })

    const { email, password, textChange } = formData
    // Handle Change from inputs
    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    // send data to backend
    const handleSubmit = e => {
        e.preventDefault();
        if(email && password ) {
            setFormData({ ...formData, textChange: 'Submitting' });
            axios.post(`${process.env.REACT_APP_API_URL}/user/login`,{ email,password })
            .then(res => {
                authenticate(res, ()=>{
                    setFormData({
                        ...formData,
                        email: '',
                        password: '',
                        textChange: 'Submitted'
                    });
                    console.log(res.data);

                    toast.success('Sign In Successful');
                });
                // redirect if authenticated
                setTimeout(function(){
                    isAuth() ? history('/') : history('/login');      
                }, 2000);
                    
            }).catch(err => {
                    setFormData({
                        ...formData,
                        email: '',
                        password: '',
                    })
                    console.log(err)
                    toast.error(err.response.data.error);
                })
        } else {
            toast.error('PLease Fill All Fields ');
        }
    }

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                {isAuth()? <Navigate to='/'/> : null }
                <ToastContainer></ToastContainer>
                <div className="login-logo">
                    <img src="dist/img/Logo_.png" alt="NIPRM" style={{ width:'350px',height:'90px' }} />
                </div>
  
                <div className="card">
                    <a style={{marginTop:'0.9rem',marginBottom:'0rem'}} className="login-logo" href='/login'><b>Admin</b>Portal</a>
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input type='email' placeholder='Email' onChange={handleChange('email')} value={email} className="form-control" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="input-group mb-3">
                                <input type='password' placeholder='Password' onChange={handleChange('password')} value={password} className="form-control" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label>Remember Me</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" style={{padding: "5px 0px"}} className="btn btn-primary btn-block">{textChange}</button>
                                </div>
                            </div>
                        </form>
                        <p className="mb-1">
                            <a href="/admin/password/forget">I forgot my password</a>
                        </p>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Login