const initialdata = [
    {
        id: '',
        title: '',
        AnnouncementFile: '',
        ApplicationFile: '',
        lastDate: '',
        createdAt: '',
        updatedAt: ''	
        
    }
]

// eslint-disable-next-line
export default  (jobs = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_JOBS':
            jobs = action.payload.data;
            return jobs;
        case 'ADD_NEW_JOB':
            let newjob = action.payload;
            jobs = [ ...jobs, newjob];
            return jobs;
        case 'UPDATE_JOB':
            for (let index = 0; index < jobs.length; index++) {
                if( jobs[index].id === action.payload.id) {
                    jobs[index] = action.payload;
                }
            }
            return jobs
        case 'REMOVE_JOB':
            let x=0;
            for (let index = 0; index < jobs.length; index++) {
                if( jobs[index].id === action.payload) {
                    x=index;
                }
            }    
            jobs.splice(x, 1);
            // console.log(categories);
            return jobs
        default:
            return jobs;
    }
}