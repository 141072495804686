import axios from "axios";

export const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

export const downloadFile = filepath => e => {
    e.preventDefault();
    let url = process.env.REACT_APP_API_URL + '/' + filepath;
    axios.get(url,{responseType: "arraybuffer"}).then((response) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = filepath.split('/')[1];
    
        document.body.appendChild(link);
    
        link.click();
        setTimeout(function () {
        window.URL.revokeObjectURL(link);
        }, 200);
    })
    .catch((error) => {console.log(error)});
}
