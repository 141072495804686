import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay, downloadFile } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons  } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getstudenttimetablesdata, updatestudentstimetable, removestudentstimetable, addnewstudentstimetable } from '../actions/studentsTimeTableActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const StudentTimeTable = () => {
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)

    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editFormData, setEditFormData] = useState({
        semester:'',
    })

    const [editAttachmentFile, setEditAttachmentFile] = useState('');
    const [editAttachment, setEditAttachment] = useState('');
    
    const onEditAttachmentFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setEditAttachmentFile(e.target.files[0]);
                setEditAttachment(e.target.files[0].name);
            }
        }
    };
    
    const handleEditChange = text => e => {
        setEditFormData({...editFormData, [text]: e.target.value})
    }
    // edit Form Fields

    // create Form Fields
    const [createFormData, setCreateFormData] = useState({
        semester:'',
    })
    
    const [createAttachmentFile, setCreateAttachmentFile] = useState('');
    const [createAttachment, setCreateAttachment] = useState('')

    const onCreateAttachmentFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setCreateAttachmentFile(e.target.files[0]);
                setCreateAttachment(e.target.files[0].name);
            }
        }
    };
    
    const handleCreateChange = text => e => {
        setCreateFormData({...createFormData, [text]: e.target.value})
    }
    // create Form Fields

    // table state
    const [data, setData] = useState([]);
    const [isLoadingDataError, setIsLoadingDataError] = useState(false);
    const [isLoadingData, setIsLoadingData ] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isDeletingData, setIsDeletingData] = useState(false);
    const [isCreatingData, setIsCreatingData] = useState(false);
    const [isUpdatingData, setIsUpdatingData] = useState(false);

     const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'semester',
                header: 'Semester',
                enableEditing: true,
            },
            {
                accessorKey: 'ttFile',
                header: 'Attachment',
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center'
                        }}
                        
                    >
                        {renderedCellValue?<button className="btn btn-secondary" onClick={downloadFile(renderedCellValue)}>Download Attachment</button>:null}
                    </Box>
                ),
                enableEditing: true,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );
 
    //CREATE action
    const handleCreateData = async ({ values, table }) => {
        setIsCreatingData(true)
        
        let formsdata = new FormData();
        formsdata.append('semester', createFormData.semester);
        formsdata.append('ttFile', createAttachment);
        formsdata.append('ttFile_file', createAttachmentFile);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/timetable/createtimetable`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addnewstudentstimetable(res.data.tt));
            fetchData();
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveData = async ({ values, table }) => {
        setIsUpdatingData(true)

        let formsdata = new FormData();
        formsdata.append('id', values.id);
        formsdata.append('semester', editFormData.semester===''?values.semester:editFormData.semester);
        formsdata.append('ttFile', editAttachment);
        formsdata.append('ttFile_file', editAttachmentFile);
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/timetable/updatetimetable`,formsdata,config);
            toast.success(res.data.message);
            dispatch(updatestudentstimetable(res.data.tt));
            fetchData();
            setEditFormData({ semester:''})
            setEditAttachment('');
            setEditAttachmentFile('');
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingData(true)
        if (window.confirm('Are you sure you want to delete this Student Result?')) {
            
            axios.delete(`${process.env.REACT_APP_API_URL}/timetable/removetimetable/${row.original.id}`,config)
            .then(res => {
                dispatch(removestudentstimetable(row.original.id));
                fetchData();
                setIsDeletingData(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingData(false)
                toast.error(err.response.data.message);
            })
        }
    };
 
    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingDataError
            ?{
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateData,
        onEditingRowSave: handleSaveData,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h4">Add New Student TimeTable</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Semester</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('semester')} value={createFormData.semester} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Attachment &nbsp;</label>
                                <span> select pdf/doc file less than 5MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onCreateAttachmentFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{createAttachment}</label>
                                    </div>
                                </div>
                            </div>
  
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h4">Edit Student TimeTable</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                        <div className="row py-2" style={{padding: "10%"}}>
                                <label>Semester</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('semester')} value={editFormData.semester===''?row.original.semester:editFormData.semester} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Attachment &nbsp;</label>
                                <span> select pdf/doc file less than 5MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onEditAttachmentFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editAttachment}</label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
              variant="contained"
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
              }}
            >
              Add New Student TimeTable
            </Button>
        ),
        state: {
            isLoading: isLoadingData,
            isSaving: isCreatingData || isUpdatingData || isDeletingData,
            showAlertBanner: isLoadingDataError,
            showProgressBars: isFetchingData,
        },
    });

    const fetchData = useCallback( async () => {
        try {
            dispatch(getstudenttimetablesdata());
            await delay(1000);
            setIsFetchingData(false)
            setIsLoadingData(false)
            let array = []
            store.getState().timetables.forEach(element => {
                array.push({
                    id: element.id,
                    semester: element.semester,
                    ttFile: element.ttFile,
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
            console.log(array)
        } catch (error) {
            setIsLoadingDataError(true)
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchData()    
    }, [fetchData]);

    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer></ToastContainer>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Student TimeTable',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} /> 
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default StudentTimeTable
