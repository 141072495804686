const initialdata = [
    {
        id: '',
        semester: '',
        esFile: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (examschedules = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_EXAMSCHEDULES':
            examschedules = action.payload.data;
            return examschedules;
        case 'ADD_NEW_EXAMSCHEDULES':
            let newschedule = action.payload;
            examschedules = [ ...examschedules, newschedule];
            return examschedules;
        case 'UPDATE_EXAMSCHEDULES':
            for (let index = 0; index < examschedules.length; index++) {
                if( examschedules[index].id === action.payload.id) {
                    examschedules[index] = action.payload;
                }
            }
            return examschedules
        case 'REMOVE_EXAMSCHEDULES':
            let x=0;
            for (let index = 0; index < examschedules.length; index++) {
                if( examschedules[index].id === action.payload) {
                    x=index;
                }
            }    
            examschedules.splice(x, 1);
            return examschedules
        default:
            return examschedules;
    }
}