import * as api from '../api';   


// get all slides
export const getmiscellaneousdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetMiscellaneousFeesData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_Miscellaneous_Fees', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new slides
export const addmiscellaneous = (NewFee) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_Miscellaneous_Fee', payload: NewFee});
    } catch (error) {
        console.log(error);
    }
}

// update slides
export const updatemiscellaneous = (fee) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_Miscellaneous_Fee', payload: fee});
    } catch (error) {
        console.log(error);
    }
}

// remove slide with id
export const removemiscellaneous = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_Miscellaneous_Fee', payload: id});
    } catch (error) {
        console.log(error);
    }
}