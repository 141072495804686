import * as api from '../api';   


// get Admissions Info
export const getadmissionsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetAdmissionsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ADMISSIONS_DATA', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// update admissions
export const updateadmissionsdata = (data) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_ADMISSIONS_DATA', payload: data});
    } catch (error) {
        console.log(error);
    }
}
