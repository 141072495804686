const initialdata = [
    {
        createdAt: "",
        email: "",
        name: "",
        id: null,
        updatedAt: "",
        profilePic: "",
        superUser: ''
    }
]

// eslint-disable-next-line
export default  (users = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_USERS':
            users = action.payload.data;
            return users;
        case 'ADD_NEW_USER':
            let newuser = action.payload;
            users = [ ...users, newuser];
            return users;
        case 'REMOVE_USER':
            let x=0;
            for (let index = 0; index < users.length; index++) {
                if( users[index].id === action.payload) {
                    x=index;
                }
            }    
            users.splice(x, 1);
            // console.log(categories);
            return users
        default:
            return users;
    }
}