import axios from 'axios';
import { getCookie } from '../helpers/auth';

const url = process.env.REACT_APP_API_URL;
// const config = {
//     headers: { Authorization: `Bearer ${getCookie('token')}` }
// };

export const GetUsersData = () => {
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    }
    return axios.get(url+'/user',config);
}
export const GetSlidersData = () => axios.get(url+'/sliders');
export const GetAdmissionsData = () => axios.get(url+'/admissions');
export const GetMiscellaneousFeesData = () => axios.get(url+'/miscellaneousfee')
export const GetDegreeProgramsData = () => axios.get(url+'/degreeprograms')
export const GetFacultiesData = () => axios.get(url+'/faculty')
export const GetEventsData = () => axios.get(url+'/events')
export const GetExamSchedulesData = () => axios.get(url+'/examschedules')
export const GetJobsData = () => axios.get(url+'/jobs')
export const GetPrincipalsMsgData = () => axios.get(url+'/principalmsg')
export const GetStudentsResultsData = () => axios.get(url+'/examresults')
export const GetStudentsTimeTableData = () => axios.get(url+'/timetable')
export const GetAnnouncementsData = () => axios.get(url+'/announcements')
export const GetDepartmentsData = () => axios.get(url+'/departments')
export const GetHospitalsData = () => axios.get(url+'/hospitals')
export const GetFacilitiesData = () => axios.get(url+'/facilities')