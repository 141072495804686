import * as api from '../api';   


// get all slides
export const getslidersdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetSlidersData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_SLIDES', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new slides
export const addnewslide = (NewSlide) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_SLIDE', payload: NewSlide});
    } catch (error) {
        console.log(error);
    }
}

// update slides
export const updateslide = (Slide) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_SLIDE', payload: Slide});
    } catch (error) {
        console.log(error);
    }
}

// remove slide with id
export const removeslide = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_SLIDE', payload: id});
    } catch (error) {
        console.log(error);
    }
}