import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getfacultydata, updatefaculty, removefaculty, addnewfaculty } from '../actions/facultyActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const Faculty = () => {
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)
    
    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editFormData, setEditFormData] = useState({
        name: '',
        email: '',
        position: '',
        department: '',
    })

    const [editImagefile, setEditImageFile] = useState('');
    const [editImageFileName, setEditImageFilename] = useState('')

    const onEditImageFileChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Please select valid image: jpg | jpeg | png')
        } else {
            if(e.target.files[0].size >= 1000000){
                toast.error('Please select image size less then 1MB')
            } else {
                setEditImageFile(e.target.files[0]);
                setEditImageFilename(e.target.files[0].name);
            }
        }
    };
    
    const handleEditChange = text => e => {
        setEditFormData({...editFormData, [text]: e.target.value})
    }
    // edit Form Fields

    // create Form Fields
    const [createFormData, setCreateFormData] = useState({
        name: '',
        email: '',
        position: '',
        department: '',
    })
    const [createImageFile, setCreateImageFile] = useState('');
    const [createImageFileName, setCreateImageFileName] = useState('')

    const handleCreateChange = text => e => {
        setCreateFormData({...createFormData, [text]: e.target.value})
    }

    const onCreateImageFileChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Please select valid image: jpg | jpeg | png')
        } else {
            if(e.target.files[0].size >= 1000000){
                toast.error('Please select image size less then 1MB')
            } else {
                setCreateImageFile(e.target.files[0]);
                setCreateImageFileName(e.target.files[0].name);
            }
        }
    };
    // create Form Fields

    // table state
    const [data, setData] = useState([]);
    const [isLoadingDataError, setIsLoadingDataError] = useState(false);
    const [isLoadingData, setIsLoadingData ] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isDeletingData, setIsDeletingData] = useState(false);
    const [isCreatingData, setIsCreatingData] = useState(false);
    const [isUpdatingData, setIsUpdatingData] = useState(false);
 
    const columns = useMemo(
        () => [
            {
            accessorKey: 'id',
            header: 'ID',
            enableEditing: false,
            size: 80,
            },
            {
            accessorKey: 'name',
            header: 'Name',
            enableEditing: true,
            },
            {
            accessorKey: 'email',
            header: 'Email',
            enableEditing: true
            },
            {
            accessorKey: 'position',
            header: 'Position',
            enableEditing: true
            },
            {
            accessorKey: 'department',
            header: 'Department',
            enableEditing: true
            },
            {
                accessorKey: 'image',
                header: 'Faculty Image',
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    justifyContent: 'center'
                    }}
                    
                >
                    <img
                    alt="avatar"
                    height={75}
                    src={renderedCellValue}
                    loading="lazy"
                    style={{  }}
                    />
                </Box>
                ),
                enableEditing: true,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );
 
    //CREATE action
    const handleCreateData = async ({ values, table }) => {
        setIsCreatingData(true)
        
        let formsdata = new FormData();
        formsdata.append('name', createFormData.name);
        formsdata.append('email', createFormData.email);
        formsdata.append('image',  createImageFileName);
        formsdata.append('position', createFormData.position);
        formsdata.append('department', createFormData.department);
        formsdata.append('imageFile', createImageFile);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/faculty/createfaculty`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addnewfaculty(res.data.faculty));
            fetchData();
            setCreateFormData({ name: '', email: '', position: '', department: ''})
            setCreateImageFileName('');
            setCreateImageFile('')
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveData = async ({ values, table }) => {
        setIsUpdatingData(true)
        
        let formsdata = new FormData();
        formsdata.append('id', values.id);
        formsdata.append('name', editFormData.name===''?values.name:editFormData.name);
        formsdata.append('email', editFormData.email===''?values.email:editFormData.email);
        formsdata.append('image', editImageFileName);
        formsdata.append('position', editFormData.position===''?values.position:editFormData.position);
        formsdata.append('department', editFormData.department===''?values.department:editFormData.department);
        formsdata.append('imageFile', editImagefile);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/faculty/updatefaculty`,formsdata,config);
            toast.success(res.data.message);
            dispatch(updatefaculty(res.data.facultydata));
            fetchData();
            setEditFormData({ name: '', email: '', position: '', department: ''})
            setEditImageFile('')
            setEditImageFilename('');
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingData(true)
        if (window.confirm('Are you sure you want to delete this Faculty?')) { 
            axios.delete(`${process.env.REACT_APP_API_URL}/faculty/removefaculty/${row.original.id}`,config)
            .then(res => {
                dispatch(removefaculty(row.original.id));
                fetchData();
                setIsDeletingData(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingData(false)
                toast.error(err.response.data.message);
            })    
        }
    }
 
    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingDataError
            ?   {
                    color: 'error',
                    children: 'Error loading data',
                }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateData,
        onEditingRowSave: handleSaveData,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Create New Faculty</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Faculty Image &nbsp;</label>
                                <span> select picture less than 1MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onCreateImageFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{createImageFileName}</label>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Name</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('name')} value={createFormData.name} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>E-mail</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('email')} value={createFormData.email} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Position</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('position')} value={createFormData.position} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Department</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('department')} value={createFormData.department} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h3">Edit Faculty</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">
                            <div style={{textAlign: 'center'}}>
                                <img src={row.original.image} className="img" alt="" style={{width:'300px', height:'225px'}} />
                            </div>
                            <div className="py-2" style={{padding: "10%", display: 'grid'}}>
                                <label>Faculty Image</label>
                                <span>select picture less than 1MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onEditImageFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editImageFileName}</label>
                                    </div>
                                </div>
                            </div>     

                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>Name</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('name')} value={editFormData.name===''?row.original.name:editFormData.name} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>E-mail</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('email')} value={editFormData.email===''?row.original.email:editFormData.email} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>Position</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('position')} value={editFormData.position===''?row.original.position:editFormData.position} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>    

                            <div className="py-2" style={{padding: "10%", display:'grid'}}>
                                <label>Department</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('department')} value={editFormData.department===''?row.original.department:editFormData.department} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" disabled={data.length<2?true:false } onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
            variant="contained"
            onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
            }}
            >
            Create New Faculty
            </Button>
        ),
        state: {
            isLoading: isLoadingData,
            isSaving: isCreatingData || isUpdatingData || isDeletingData,
            showAlertBanner: isLoadingDataError,
            showProgressBars: isFetchingData,
        },
    });

    const fetchData = useCallback( async () => {
        try {
        // dispatch(getfacultydata());
        // let Facultiesdata = useSelector((state) => state.faculties);

        dispatch(getfacultydata());
        await delay(1000);
        setIsFetchingData(false)
        setIsLoadingData(false)
        let array = []
        store.getState().faculties.forEach(element => {
            array.push({
                id: element.id,
                name: element.name,
                email: element.email,
                image: process.env.REACT_APP_API_URL+'/'+element.image,
                position: element.position,
                department: element.department,
                updatedOn: element.updatedAt.substring(0, 10),
                createdOn: element.createdAt.substring(0, 10)
            })
        });
        setData(array)
    } catch (error) {
        setIsLoadingDataError(true)
    }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchData()    
    }, [fetchData]);


    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer></ToastContainer>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Faculty',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Faculty
