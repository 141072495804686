import * as api from '../api';   


// get all timetables
export const getstudenttimetablesdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetStudentsTimeTableData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_STUDENTS_TimeTable', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new students TimeTable
export const addnewstudentstimetable = (NewTimeTable) => async (dispatch) => {
    try {
        
        dispatch({ type: 'ADD_NEW_STUDENTS_TimeTable', payload: NewTimeTable});
    } catch (error) {
        console.log(error);
    }
}

// update student TimeTable
export const updatestudentstimetable = (StudentTimeTable) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_STUDENTS_TimeTable', payload: StudentTimeTable});
    } catch (error) {
        console.log(error);
    }
}

// remove students TimeTable with id
export const removestudentstimetable = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_STUDENTS_TimeTable', payload: id});
    } catch (error) {
        console.log(error);
    }
}