const initialdata = [
    {
        id: '',
        title: '',
        eventImages: [],
        eventDescription: '',
        dateOccurred: '',
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (events = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_EVENTS':
            events = action.payload.data;
            return events;
        case 'ADD_NEW_EVENT':
            let newevent = action.payload;
            events = [ ...events, newevent];
            return events;
        case 'UPDATE_EVENT':
            for (let index = 0; index < events.length; index++) {
                if( events[index].id === action.payload.id) {
                    events[index] = action.payload;
                }
            }
            return events
        case 'REMOVE_EVENT':
            let x=0;
            for (let index = 0; index < events.length; index++) {
                if( events[index].id === action.payload) {
                    x=index;
                }
            }    
            events.splice(x, 1);
            return events
        default:
            return events;
    }
}