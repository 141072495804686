import * as api from '../api';   


// get all user
export const getusersdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetUsersData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_USERS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new user
export const addnewuser = (NewUser) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_USER', payload: NewUser});
    } catch (error) {
        console.log(error);
    }
}

// remove user with id
export const removeuser = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_USER', payload: id});
    } catch (error) {
        console.log(error);
    }
}