import * as api from '../api';   


// get principals msg Info
export const getprincipalsmsgdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetPrincipalsMsgData();
        // console.log(data)

        dispatch({ type: 'FETCH_PRINCIPALS_MSG', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// update principals msg info
export const updateprincipalsmsgdata = (data) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_PRINCIPALS_MSG', payload: data});
    } catch (error) {
        console.log(error);
    }
}
