const initialdata = [
    {
        id: '',
        name: '',
        description: ''
    }
]

// eslint-disable-next-line
export default  (departments = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_Departments':
            departments = action.payload.data;
            return departments;
        case 'ADD_NEW_Department':
            let newfee = action.payload;
            departments = [ ...departments, newfee];
            return departments;
        case 'UPDATE_Department':
            for (let index = 0; index < departments.length; index++) {
                if( departments[index].id === action.payload.id) {
                    departments[index] = action.payload;
                }
            }
            return departments
        case 'REMOVE_Department':
            let x=0;
            for (let index = 0; index < departments.length; index++) {
                if( departments[index].id === action.payload) {
                    x=index;
                }
            }    
            departments.splice(x, 1);
            // console.log(categories);
            return departments
        default:
            return departments;
    }
}