import * as api from '../api';   


// get all faculty
export const getfacultydata = () => async (dispatch) => {
    try {
        const { data } = await api.GetFacultiesData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_FACULTY', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new FACULTY
export const addnewfaculty = (NewFaculty) => async (dispatch) => {
    try {
        dispatch({ type: 'ADD_NEW_FACULTY', payload: NewFaculty});
    } catch (error) {
        console.log(error);
    }
}

// update FACULTY
export const updatefaculty = (Faculty) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_FACULTY', payload: Faculty});
    } catch (error) {
        console.log(error);
    }
}

// remove FACULTY with id
export const removefaculty = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_FACULTY', payload: id});
    } catch (error) {
        console.log(error);
    }
}