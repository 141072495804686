import { combineReducers } from 'redux';
import users from './users';
import sliders from './sliders';
import admissions from './admissions';
import miscellaneousfees from './miscellaneousfees';
import degreeprograms from './degreeprograms';
import faculties from './faculties';
import events from './events';
import examschedules from './examschedules';
import jobs from './jobs';
import principalsmsg from './principalsmsg';
import studentresults from './studentresults';
import timetables from './timetables';
import announcements from './announcements';
import departments from './departments';
import hospitals from './hospitals';
import facilities from './facilities';

export default combineReducers({ users, sliders, admissions, miscellaneousfees, studentresults, announcements, 
    degreeprograms, faculties, events, examschedules, jobs, principalsmsg, timetables, departments, hospitals, facilities });