import * as api from '../api';   


// get all hospitals
export const gethospitalsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetHospitalsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_Hospitals', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new hospital
export const addhospital = (NewHosp) => async (dispatch) => {
    try {
        // console.log(NewCategory);
        dispatch({ type: 'ADD_NEW_Hospital', payload: NewHosp});
    } catch (error) {
        console.log(error);
    }
}

// update hospital
export const updatehospital = (hosp) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_Hospital', payload: hosp});
    } catch (error) {
        console.log(error);
    }
}

// remove hospital with id
export const removehospital = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_Hospital', payload: id});
    } catch (error) {
        console.log(error);
    }
}