const initialdata = [
    {
        id: '',
        name: '',
        images: [],
        createdAt: '',
        updatedAt: ''
    }
]

// eslint-disable-next-line
export default  (facilities = initialdata, action) => {
    switch (action.type) {
        case 'FETCH_ALL_FACILITIES':
            facilities = action.payload.data;
            return facilities;
        case 'ADD_NEW_FACILITY':
            let newFacility = action.payload;
            facilities = [ ...facilities, newFacility];
            return facilities;
        case 'UPDATE_FACILITY':
            for (let index = 0; index < facilities.length; index++) {
                if( facilities[index].id === action.payload.id) {
                    facilities[index] = action.payload;
                }
            }
            return facilities
        case 'REMOVE_FACILITY':
            let x=0;
            for (let index = 0; index < facilities.length; index++) {
                if( facilities[index].id === action.payload) {
                    x=index;
                }
            }    
            facilities.splice(x, 1);
            return facilities
        default:
            return facilities;
    }
}