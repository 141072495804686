import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { isAuth } from '../../helpers/auth';
import { Navigate } from 'react-router-dom';

const Reset = ({match}) => {
  const [formData, setFormData] = useState({
    password1: '',
    password2: '',
    textChange: 'Submit'
  });
  var Token = '';
  
  const { password1, password2, textChange } = formData;
  
  useEffect(() => {
      let token = match.params.token
      if(token) {
        // eslint-disable-next-line
          Token=token
      }
      console.log(Token)
      
  }, [match.params, formData])
    
  const handleChange = text => e => {
    setFormData({ ...formData, [text]: e.target.value });
  };
    
  const handleSubmit = e => {
    e.preventDefault();
    if ((password1 === password2) && password1 && password2) {
      setFormData({ ...formData, textChange: 'Submitting' });
      axios.put(`${process.env.REACT_APP_API_URL}/user/password/reset`, {
            newPassword: password1,
            resetPasswordLink: Token
        })
        .then(res => {
          // console.log(res.data.message)
            setFormData({
              ...formData,
               password1: '',
              password2: ''
            });
            toast.success(res.data.message);
          
        })
        .catch(err => {
          // console.log(err.response);
          toast.error(err.response.data.error);
          setFormData({ ...formData, textChange: 'Submit' });
        });
    } else {
      toast.error('Passwords don\'t matches');
    }
  };
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
          {isAuth()? <Navigate to='/'/> : null }
          <ToastContainer></ToastContainer>
          <div className="login-logo">
            <img src="dist/img/Logo_.png" alt="NIPRM" style={{ width:'350px',height:'90px' }} />
          </div>
          
          <div className="card">
            <a style={{marginTop:'0.9rem',marginBottom:'0rem'}} className="login-logo" href='/login'><b>Admin</b>Portal</a>
            <div className="card-body login-card-body">
              <p className="login-box-msg">You are only one step a way from your new password, recover your password now.</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input className='form-control' type='password' placeholder='password' onChange={handleChange('password1')} value={password1} />
                  <div className="input-group-append">
                    <div className="input-group-text"><span className="fas fa-lock"></span></div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input className='form-control' type='password' placeholder='Confirm password' onChange={handleChange('password2')} value={password2} />
                  <div className="input-group-append">
                    <div className="input-group-text"><span className="fas fa-lock"></span></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4" style={{margin: 'auto'}}>
                    <button type='submit' className='btn btn-primary btn-block'>{textChange}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Reset;