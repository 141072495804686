import * as api from '../api';   


// get all students results
export const getstudentresultsdata = () => async (dispatch) => {
    try {
        const { data } = await api.GetStudentsResultsData();
        // console.log(data)

        dispatch({ type: 'FETCH_ALL_STUDENTS_RESULT', payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

// Add new students result
export const addnewstudentsresult = (NewResult) => async (dispatch) => {
    try {
        
        dispatch({ type: 'ADD_NEW_STUDENTS_RESULT', payload: NewResult});
    } catch (error) {
        console.log(error);
    }
}

// update student result
export const updatestudentsresult = (StudentResult) => async (dispatch) => {
    try {
        dispatch({ type: 'UPDATE_STUDENTS_RESULT', payload: StudentResult});
    } catch (error) {
        console.log(error);
    }
}

// remove students result with id
export const removestudentsresult = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'REMOVE_STUDENTS_RESULT', payload: id});
    } catch (error) {
        console.log(error);
    }
}