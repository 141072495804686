import React from "react";
import { isAuth } from "../helpers/auth";

const Menu = (props) => {
  let user = {
      name: '',
      profilePic: ''
  }
  if(isAuth()){
    let temp = isAuth();
    user = { name: temp.name, profilePic: process.env.REACT_APP_API_URL+'/'+temp.profilePic}
  }
  
  return (
    <div style={{position: "fixed", height:"100vh", top: "0px"}} >
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" className="brand-link" style={{ padding: "0px" }}>
          <img
            src="dist/img/Logo_.png"
            alt="NIPRM"
            style={{ width:'250px', height:'56px', backgroundColor:'whitesmoke' }}
          />
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={user.profilePic}
                className="img-circle elevation-2"
                alt="User"
                style={{width:'60px',height:'40px'}}
              />
            </div>
            <div className="info">
              <a href="/" className="d-block">
                {user.name}
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul 
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p>Account</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/principalmessage" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Principal Message</p>
                </a>
              </li>
              
              
              <li className="nav-item">
                <a href="/sliders" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Slider Settings</p>
                </a>
              </li>
              
              <li className="nav-item">
                <a href="/admissions" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Admissions</p>
                </a>
              </li>
              
              <li className="nav-item">
                <a href="/departments" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Departments</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/miscellaneousfees" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Miscellaneous Fees</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/degreeprograms" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Degree Programs</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/events" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Events</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/announcements" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Announcement</p>
                </a>
              </li>

              {/* <li className="nav-item">
                <a href="/news" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>News</p>
                </a>
              </li> */}

              <li className="nav-item">
                <a href="/examschedules" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Exam Schedules</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/studentresults" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Student Results</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/studentstimetable" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Students TimeTable</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/faculty" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Faculty</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/jobs" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Jobs</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="/hospitals" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Hospitals</p>
                </a>
              </li>
              
              <li className="nav-item">
                <a href="/facilities" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h" />
                  <p>Facilities</p>
                </a>
              </li>
              
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Menu;
