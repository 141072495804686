import React, { useState, useEffect } from 'react'
import { isAuth, getCookie } from '../helpers/auth';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { useSelector } from 'react-redux';
import { updateadmissionsdata } from '../actions/admissionActions';
import { useDispatch } from 'react-redux';

import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const Admissions = () => {
    const dispatch = useDispatch();
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    const Admissionsdata = useSelector((state) => state.admissions);

    const [formData, setFormData] = useState({
        adStatus:  '',
        adTestFee: Admissionsdata.adTestFee,
        admissionFeeMerit: Admissionsdata.admissionFeeMerit,
        admissionFeeSelf: Admissionsdata.admissionFeeSelf,
        admissionFeeOverseas: Admissionsdata.admissionFeeOverseas,
        securityDeposit: Admissionsdata.securityDeposit
    });
    
    useEffect(() => {
        setFormData({
            adStatus: '',
            adTestFee: Admissionsdata.adTestFee,
            admissionFeeMerit: Admissionsdata.admissionFeeMerit,
            admissionFeeSelf: Admissionsdata.admissionFeeSelf,
            admissionFeeOverseas: Admissionsdata.admissionFeeOverseas,
            securityDeposit: Admissionsdata.securityDeposit
        })
     },[Admissionsdata])
     
    // console.log(formData)
    const { adStatus, adTestFee, admissionFeeMerit, admissionFeeSelf, admissionFeeOverseas, securityDeposit } = formData;
     
    const [lastDateToApply, setLastDateToApply] = useState(null);
    const [adEligibleDate, setAdEligibleDate] = useState(null);
    const [adTestDate, setAdTestDate] = useState(null);
    const [adResultDate, setAdResultDate] = useState(null);
    const [adFormalitiesDateStart, setAdFormalitiesDateStart] = useState(null);
    const [adFormalitiesDateEnd, setAdFormalitiesDateEnd] = useState(null);
    
    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    const [adAppFormFile, setAdAppFormFile] = useState('');
    const [adAppForm, setAdAppForm] = useState('')

    const onFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setAdAppFormFile(e.target.files[0]);
                setAdAppForm(e.target.files[0].name);
            }
        }
    };

    // send data to backend
    const handleSubmit = async e => {
        e.preventDefault();
        const dates = {
            ld: lastDateToApply?new Date(lastDateToApply).toString().substring(0,15):Admissionsdata.lastDateToApply,
            ed: adEligibleDate?new Date(adEligibleDate).toString().substring(0,15):Admissionsdata.adEligibleDate,
            td: adTestDate?new Date(adTestDate).toString().substring(0,15):Admissionsdata.adTestDate,
            rd: adResultDate?new Date(adResultDate).toString().substring(0,15):Admissionsdata.adResultDate,
            fd: adFormalitiesDateStart?'from '+new Date(adFormalitiesDateStart).toString().substring(0,15) + ' to '+ new Date(adFormalitiesDateEnd).toString().substring(0,15):Admissionsdata.adFormalitiesDate
        }
        // console.log(dates)
        let formsdata = new FormData();
        
        adStatus===''?formsdata.append('adStatus', Admissionsdata.adStatus):formsdata.append('adStatus', adStatus);
        formsdata.append('lastDateToApply', dates.ld);
        formsdata.append('adEligibleDate', dates.ed);
        formsdata.append('adTestDate', dates.td);
        formsdata.append('adResultDate', dates.rd);
        formsdata.append('adFormalitiesDate', dates.fd);
        formsdata.append('adAppForm', adAppForm);
        formsdata.append('adTestFee', adTestFee);
        formsdata.append('admissionFeeMerit', admissionFeeMerit);
        formsdata.append('admissionFeeSelf', admissionFeeSelf);
        formsdata.append('admissionFeeOverseas', admissionFeeOverseas);
        formsdata.append('securityDeposit', securityDeposit);
        formsdata.append('file', adAppFormFile);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/admissions/updateAdmissions`, formsdata, config);
            toast.success(res.data.message);
            dispatch(updateadmissionsdata(res.data.data));
            setFormData({
                adStatus: null,
                adTestFee: Admissionsdata.adTestFee,
                admissionFeeMerit: Admissionsdata.admissionFeeMerit,
                admissionFeeSelf: Admissionsdata.admissionFeeSelf,
                admissionFeeOverseas: Admissionsdata.admissionFeeOverseas,
                securityDeposit: Admissionsdata.securityDeposit
            })
            setAdAppForm('');
            setAdAppFormFile('')
            setTimeout(() => {
                window.location.reload(false);    
            }, 2000);
            
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
        }
    }

    const downloadFile = e => {
        e.preventDefault();
        let url = process.env.REACT_APP_API_URL + '/' + Admissionsdata.adAppForm;
        axios.get(url,{responseType: "arraybuffer"}).then((response) => {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/octet-stream" })
              );
              link.download = Admissionsdata.adAppForm.split('/')[1];
          
              document.body.appendChild(link);
          
              link.click();
              setTimeout(function () {
                window.URL.revokeObjectURL(link);
              }, 200);
            })
            .catch((error) => {console.log(error)});
    }
    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer></ToastContainer>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Admissions',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <form onSubmit={handleSubmit}>
                            <div className="wrapper bg-white border">
                                
                                <div className="py-2">
                                    
                                    <div className="row py-2" style={{padding: "10%"}}>
                                        <div className="col-md">
                                            <label>Admissions Status</label>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-check form-check-inline">
                                                <input value={true} onChange={handleChange('adStatus')} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"/>
                                                <label className="form-check-label" htmlFor="inlineRadio1">Opened</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input value={false} onChange={handleChange('adStatus')} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"/>
                                                <label className="form-check-label" htmlFor="inlineRadio2">Closed</label>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <label>Current Status: &nbsp;</label>
                                            <span>{Admissionsdata.adStatus?'Opened':'Closed'}</span>
                                        </div>
                                    </div>

                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Last Date To Apply For Admissions</label>
                                        </div>
                                        <div className="col-md">
                                            <input aria-label="Date" type="date" onChange={(e)=>setLastDateToApply(e.target.value)} value={lastDateToApply} />
                                        </div>
                                        <div className="col-md">
                                            <label>Current: &nbsp;</label>
                                            <span>{Admissionsdata.lastDateToApply}</span>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Date to Announce Eligible Candidates</label>
                                        </div>
                                        <div className="col-md">
                                            <input aria-label="Date" type="date" onChange={(e)=>setAdEligibleDate(e.target.value)} value={adEligibleDate} />
                                        </div>
                                        <div className="col-md">
                                            <label>Current: &nbsp;</label>
                                            <span>{Admissionsdata.adEligibleDate}</span>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Date Of Admission Test</label>
                                        </div>
                                        <div className="col-md">
                                            <input aria-label='Date' type='date' onChange={(e)=>setAdTestDate(e.target.value)} value={adTestDate} />
                                        </div>
                                        <div className="col-md">
                                            <label>Current: &nbsp;</label>
                                            <span>{Admissionsdata.adTestDate}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Date For Results Announcement</label>
                                        </div>
                                        <div className="col-md">
                                            <input aria-label='Date' type='date' onChange={(e)=>setAdResultDate(e.target.value)} value={adResultDate} />
                                        </div>
                                        <div className="col-md">
                                            <label>Current: &nbsp;</label>
                                            <span>{Admissionsdata.adResultDate}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Dates For Admission Formalities</label>
                                        </div>
                                        <div className="col-md">
                                            <input aria-label='Date' type='date' onChange={(e)=>setAdFormalitiesDateStart(e.target.value)} value={adFormalitiesDateStart} />
                                            <label>&nbsp; - &nbsp;</label>
                                            <input aria-label='Date' type='date' onChange={(e)=>setAdFormalitiesDateEnd(e.target.value)} value={adFormalitiesDateEnd} />
                                        </div>
                                        <div className="col-md">
                                            <label>Current: &nbsp;</label>
                                            <span>{Admissionsdata.adFormalitiesDate}</span>

                                        </div>
                                    </div>

                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Admission Fees Merit</label>
                                        </div>
                                        <div className="col-md">
                                            <div className="input-group">
                                                
                                                <input type='number' onChange={handleChange('admissionFeeMerit')} value={admissionFeeMerit} className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-dollar-sign"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Admission Fees Self-Finance</label>
                                        </div>
                                        <div className="col-md">
                                            <div className="input-group">
                                                
                                                <input type='number' onChange={handleChange('admissionFeeSelf')} value={admissionFeeSelf} className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-dollar-sign"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Admission Fees Overseas</label>
                                        </div>
                                        <div className="col-md">
                                            <div className="input-group">
                                                
                                                <input type='number' onChange={handleChange('admissionFeeOverseas')} value={admissionFeeOverseas} className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-dollar-sign"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Admission Test Fees</label>
                                        </div>
                                        <div className="col-md">
                                            <div className="input-group">
                                                
                                                <input type='number' onChange={handleChange('adTestFee')} value={adTestFee} className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-dollar-sign"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <label>Security Deposit</label>
                                        </div>
                                        <div className="col-md">
                                            <div className="input-group">
                                                
                                                <input type='number' onChange={handleChange('securityDeposit')} value={securityDeposit} className="bg-light form-control" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-dollar-sign"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-2" style={{padding:'10%'}}> 
                                        <div className="col-md">
                                            <label>Application's Form &nbsp;</label>
                                            <span>select pdf/doc file less than 5MB</span>
                                        </div>
                                    </div>
                                    <div className="row py-2" style={{padding: '10%'}}>
                                        <div className="col-md">
                                            <div className="custom-file">
                                                <div className="custom-file">
                                                    <input type="file" onChange={onFileChange} className="custom-file-input" id="Customfile" />
                                                    <label className="custom-file-label" htmlFor="Customfile">{adAppForm}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {Admissionsdata.adAppForm?<div className="col-md">
                                            <button className="btn btn-secondary ml-3" onClick={downloadFile}>Download Admission's Application form</button>
                                        </div>:null}
                                    </div>
                                </div>
                                
                                <div className="py-2 text-center border-bottom"> 
                                    <button type='submit' className="btn btn-secondary mr-3">Update Admissions Info</button> 
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Admissions
