import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { isAuth, getCookie } from '../helpers/auth';
import { delay, downloadFile } from '../helpers/utilities';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Material Table
import { MaterialReactTable, useMaterialReactTable, MRT_EditActionButtons as MRTEditActionButtons } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Redux
import { ReactReduxContext } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getjobsdata, updatejob, removejob, addnewjob } from '../actions/jobActions';

// components
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Breadcumbs from '../components/Breadcumbs';

const Jobs = () => {
    const dispatch = useDispatch();
    
    // Access the store via the `useContext` hook
    const { store } = useContext(ReactReduxContext)

    // Authorization Config for Axios
    const config = {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
    };

    // edit Form Fields
    const [editFormData, setEditFormData] = useState({
        title:'',
        lastDate: '',
    })

    const [editAnnouncementFile, setEditAnnouncementFile] = useState('');
    const [editAnnouncementFileName, setEditAnnouncementFileName] = useState('')
    
    const [editApplicationFile, setEditApplicationFile] = useState('');
    const [editApplicationFileName, setEditApplicationFileName] = useState('');

    const onEditAnnouncementFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setEditAnnouncementFile(e.target.files[0]);
                setEditAnnouncementFileName(e.target.files[0].name);
            }
        }
    };
    
    const onEditApplicationFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setEditApplicationFile(e.target.files[0]);
                setEditApplicationFileName(e.target.files[0].name);
            }
        }
    };
    
    const handleEditChange = text => e => {
        setEditFormData({...editFormData, [text]: e.target.value})
    }
    // edit Form Fields

    // create Form Fields
    const [createFormData, setCreateFormData] = useState({
        title:'',
        lastDate: '',
    })
    
    const [createAnnouncementFile, setCreateAnnouncementFile] = useState('');
    const [createAnnouncementFileName, setCreateAnnouncementFileName] = useState('')
    
    const [createApplicationFile, setCreateApplicationFile] = useState('');
    const [createApplicationFileName, setCreateApplicationFileName] = useState('');

    const onCreateAnnouncementFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setCreateAnnouncementFile(e.target.files[0]);
                setCreateAnnouncementFileName(e.target.files[0].name);
            }
        }
    };
    
    const onCreateApplicationFileChange = e => {
        if (!e.target.files[0].name.match(/\.(pdf|doc|docx)$/)) {
            toast.error('Please select valid document: pdf | doc | docx')
        } else {
            if(e.target.files[0].size >= 5000000){
                toast.error('Please select file size less then 5MB')
            } else {
                setCreateApplicationFile(e.target.files[0]);
                setCreateApplicationFileName(e.target.files[0].name);
            }
        }
    };
    
    const handleCreateChange = text => e => {
        setCreateFormData({...createFormData, [text]: e.target.value})
    }
    // create Form Fields

    // table state
    const [data, setData] = useState([]);
    const [isLoadingDataError, setIsLoadingDataError] = useState(false);
    const [isLoadingData, setIsLoadingData ] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isDeletingData, setIsDeletingData] = useState(false);
    const [isCreatingData, setIsCreatingData] = useState(false);
    const [isUpdatingData, setIsUpdatingData] = useState(false);
    	
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'title',
                header: 'Job Title',
                enableEditing: true,
            },
            {
                accessorKey: 'announcementFile',
                header: 'Announcement File',
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center'
                        }}
                        
                    >
                        {renderedCellValue?<button className="btn btn-secondary" onClick={downloadFile(renderedCellValue)}>Download Announcement File</button>:null}
                    </Box>
                ),
                enableEditing: true,
            },
            {
                accessorKey: 'applicationFile',
                header: 'Application File',
                size: 200,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center'
                        }}
                        
                    >
                        {renderedCellValue?<button className="btn btn-secondary" onClick={downloadFile(renderedCellValue)}>Download Application File</button>:null}
                    </Box>
                ),
                enableEditing: true,
            },
            {
                accessorKey: 'lastDate',
                header: 'Last Date To Apply',
                enableEditing: true,
            },
            {
                accessorKey: 'updatedOn',
                header: 'Updated On',
                enableEditing: false,
            },
            {
                accessorKey: 'createdOn',
                header: 'Created On',
                enableEditing: false,
            },
        ],
        [],
    );

     //CREATE action
     const handleCreateData = async ({ values, table }) => {
        setIsCreatingData(true)
        var dateObject = new Date(createFormData.lastDate);

        let formsdata = new FormData();
        formsdata.append('title', createFormData.title);
        formsdata.append('AnnouncementFile', createAnnouncementFileName);
        formsdata.append('ApplicationFile', createApplicationFileName);
        formsdata.append('AnnouncementFile_file', createAnnouncementFile);
        formsdata.append('ApplicationFile_file', createApplicationFile);
        formsdata.append('lastDate', dateObject.toString().substring(0,15));
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/jobs/createjob`, formsdata, config);
            toast.success(res.data.message);
            dispatch(addnewjob(res.data.job));
            fetchData();
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsCreatingData(false)
            table.setCreatingRow(null); //exit creating mode
        }
    };
    //UPDATE action
    const handleSaveData = async ({ values, table }) => {
        setIsUpdatingData(true)
        var dateObject = new Date(editFormData.lastDate);
        let formsdata = new FormData();
        formsdata.append('id', values.id);
        formsdata.append('title', editFormData.title===''?values.title:editFormData.title);
        formsdata.append('lastDate', editFormData.lastDate===''?values.lastDate:dateObject.toString().substring(0,15));
        formsdata.append('AnnouncementFile', editAnnouncementFileName);
        formsdata.append('ApplicationFile', editApplicationFileName);
        formsdata.append('AnnouncementFile_file', editAnnouncementFile);
        formsdata.append('ApplicationFile_file', editApplicationFile);
       
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/jobs/updatejob`,formsdata,config);
            toast.success(res.data.message);
            dispatch(updatejob(res.data.jobdata));
            fetchData();
            setEditFormData({ title:'', lastDate: ''})
            setEditAnnouncementFile('');
            setEditAnnouncementFileName('');
            setEditApplicationFile('');
            setEditApplicationFileName('');
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        } catch (err) {
            toast.error(err.response.data.error);
            console.log(err)
            setIsUpdatingData(false)
            table.setEditingRow(null); //exit editing mode
        }
    };
    //DELETE action
    const openDeleteConfirmModal = (row) => {
        setIsDeletingData(true)
        if (window.confirm('Are you sure you want to delete this Job?')) {
        
            axios.delete(`${process.env.REACT_APP_API_URL}/jobs/removejob/${row.original.id}`,config)
            .then(res => {
                dispatch(removejob(row.original.id));
                fetchData();
                setIsDeletingData(false)
                toast.success(res.data.message); 
            }).catch(err => {     
                setIsDeletingData(false)
                toast.error(err.response.data.message);
            })
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingDataError
            ?   {
                    color: 'error',
                    children: 'Error loading data',
                }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowSave: handleCreateData,
        onEditingRowSave: handleSaveData,
        //optionally customize modal content
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h4">Add New Job</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Job Title</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleCreateChange('title')} value={createFormData.title} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Last Date To Apply</label>
                                <div className="input-group">
                                    <input aria-label="Date" type="date" onChange={handleCreateChange('lastDate')} value={createFormData.lastDate} />
                                </div>
                            </div>

                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Job Announcement File &nbsp;</label>
                                <span> select pdf/doc file less than 5MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onCreateAnnouncementFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{createAnnouncementFileName}</label>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Job's Application File &nbsp;</label>
                                <span> select pdf/doc file less than 5MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onCreateApplicationFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{createApplicationFileName}</label>
                                    </div>
                                </div>
                            </div>
  
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>  
                <DialogTitle variant="h4">Edit Job</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {/* {internalEditComponents} or render custom edit components here */}
                    <div className="wrapper bg-white border">
                        <div className="py-2">

                        <div className="row py-2" style={{padding: "10%"}}>
                                <label>Job Title</label>
                                <div className="input-group">
                                    <input type='text' onChange={handleEditChange('title')} value={editFormData.title===''?row.original.title:editFormData.title} className="bg-light form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-pen"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>     

                            <div className="row py-2" style={{padding: "10%"}}>
                                <label>Last Date To Apply</label>
                                <div className="input-group">
                                    <div className="col-md">
                                        <input aria-label="Date" type="date" onChange={handleEditChange('lastDate')} value={editFormData.lastDate} />
                                    </div>
                                    <div className="col-md">
                                        <label>Current: &nbsp;</label>
                                        <span>{row.original.lastDate}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Job Announcement File &nbsp;</label>
                                <span> select pdf/doc file less than 5MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onEditAnnouncementFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editAnnouncementFileName}</label>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-2" style={{padding:'10%'}}> 
                                <label>Job's Application File &nbsp;</label>
                                <span> select pdf/doc file less than 5MB</span>
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" onChange={onEditApplicationFileChange} className="custom-file-input" id="Customfile" />
                                        <label className="custom-file-label" htmlFor="Customfile">{editApplicationFileName}</label>
                                    </div>
                                </div>
                            </div>
                           
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <MRTEditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
              variant="contained"
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                //or you can pass in a row object to set default values with the `createRow` helper function
                // table.setCreatingRow(
                //   createRow(table, {
                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                //   }),
                // );
              }}
            >
              Add New Job
            </Button>
        ),
        state: {
            isLoading: isLoadingData,
            isSaving: isCreatingData || isUpdatingData || isDeletingData,
            showAlertBanner: isLoadingDataError,
            showProgressBars: isFetchingData,
        },
    });

    const fetchData = useCallback( async () => {
        try {
            dispatch(getjobsdata());
            await delay(1000);
            setIsFetchingData(false)
            setIsLoadingData(false)
            let array = []
            store.getState().jobs.forEach(element => {
                array.push({
                    id: element.id,
                    title: element.title,
                    announcementFile: element.AnnouncementFile,
                    applicationFile: element.ApplicationFile,
                    lastDate: element.lastDate,
                    updatedOn: element.updatedAt.substring(0, 10),
                    createdOn: element.createdAt.substring(0, 10)
                })
            });
            setData(array)
        } catch (error) {
            setIsLoadingDataError(true)
        }
    },[dispatch, store])
    
    useEffect(()=>{
        fetchData()    
    }, [fetchData]);

    return (
        <div>
            {isAuth()? null : <Navigate to='/login'/> }
            <Header></Header>
            <Menu></Menu>
            <ToastContainer></ToastContainer>
            <div className="content-wrapper" style={{minHeight:'600px !important'}}>
                <Breadcumbs data={ [[false],['Home','/'],['Jobs',null]] }></Breadcumbs>
                <div className="row">
                    <div className="col-12" style={{padding: '0px 20px'}}>
                        <MaterialReactTable table={table} />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Jobs
